
export const accountHoldingHeaders = [
  { id: "symbol", label: "Symbol" },
  { id: "assetName", label: "Asset Name" },
  { id: "shares", label: "Shares" },
  { id: "percentHolding", label: "% Holdings" },
  { id: "marketValue", label: "Market Value" },
  { id: "dayChange", label: "Day Change" },
  { id: "percentDayChange", label: "Day Change (%)" }
];

const accountListContent = [
  {
    symbol: "AAPL",
    assetName: "Appl Inc.",
    percentHolding: 25,
    marketValue: 234.21,
    dayChange: 0,
    percentDayChange: 0,
    shares:23,
  },
  {
    symbol: "VIZ",
    assetName: "Viz Inc.",
    percentHolding: 34,
    marketValue: 567.40,
    dayChange: -0,
    percentDayChange: -0,
    shares:2.30,

  },
  {
    symbol: "XYZ",
    assetName: "Xyz Inc.",
    percentHolding: 35,
    marketValue: 12.4,
    dayChange: 0.0001,
    percentDayChange: +0.0001,
    shares:20,

  },
  {
    symbol: "ABC",
    assetName: "ABC Inc.",
    percentHolding: 56,
    marketValue: 34.65,
    dayChange: -0.0003,
    percentDayChange: -0.0003,
    shares:13,

  },
  {
    symbol: "TWTT",
    assetName: "Twt Inc.",
    percentHolding: 90,
    marketValue: 123.5,
    dayChange: 43.54,
    percentDayChange: 43.54,
    shares:3,

  },
  {
    symbol: "TWT",
    assetName: "Twt Inc.",
    percentHolding: 90,
    marketValue: 123.5,
    dayChange: -4.54,
    percentDayChange: -4.54,
    shares:3,

  },
];

const AccountData = [
  {
  
    holdingsTitle: "US Stock",
    accordionArray: [
      {
        holdingsSubtitle: "US Large Cap",
        accountListHeaders: accountHoldingHeaders,
        accountListContent,
      },
      {
        holdingsSubtitle: "US Mid Cap",
        accountListHeaders: accountHoldingHeaders,
        accountListContent,
      },
    ],
  },
  {
    holdingsTitle: "International Stock",
    accordionArray: [
      {
        holdingsSubtitle: "International Large Cap",
        accountHoldingHeaders,
        accountListContent,
      },
      {
        holdingsSubtitle: "International Mid Cap",
        accountHoldingHeaders,
        accountListContent,
      },
    ],
  },
] ;

export const AccountHoldingsData={
  cardTitle:'Holdings',
  holdingsArray:AccountData,
}
