import { PageHeaderAction, PageHeaderActionType } from "./PageHeader.action-types";

const INIT_STATE = {
  data: null,
  error: "",
};

let PageHeaderReducer = (state = INIT_STATE, action: PageHeaderAction) => {
  if (action.type === PageHeaderActionType.SET_PAGEHEADER) {
    return { data: action.payload };
  } else {
    return { ...state };
  }
};

export default PageHeaderReducer;
