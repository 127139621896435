import { call, put } from "redux-saga/effects";

import { networthDetailHoldingFormatter } from "./NetWorthDetailHoldingFormatterHelpers";
import { mgpService, errMessage } from "../../../general-config";
import { getCookies } from "../../../ui/utils/helper";
import { networthGraphFormatter } from "../Networth/NetWorthGraphFormatterHelpers";
import { fetchApi, mgpPortfolio } from "../saga-index";
import { getNetWorthDetailSuccess, getNetWorthDetailError } from "./NetWorthDetail.actions";

export const networthDetailCall = (portfolioId : string) =>
  fetchApi(`${mgpService}/networth/details?portfolioId=${portfolioId}`)

export function* getNetworthDetailSaga(): any {
  try {
    const token = getCookies('access_token');
    if(token) {
      const portfolioId = yield call(mgpPortfolio);
      if(portfolioId) {
        const response = yield call(networthDetailCall, portfolioId);
        if(response?.data) {
          const graphData = yield call(
            networthGraphFormatter,
            response?.data
          );
          const holdingsData=yield call(networthDetailHoldingFormatter,response?.data?.holdings)
          yield put(getNetWorthDetailSuccess({netWorthGraph:graphData,netWorthHoldings:holdingsData}));
        }
        else {
          yield put(getNetWorthDetailError({errorMessage:errMessage,responseCode:500}));
        }
      }
      else {
        yield put(getNetWorthDetailError({errorMessage:errMessage,responseCode:500}));
      }
    }
    
  } catch (e : any) {
    yield put(getNetWorthDetailError({errorMessage:e?.response?.data?.message || errMessage,responseCode:e?.response?.data?.code||500}));
  }
}