const MapImage=require('../../images/Map.webp');

export const contactModalMock = {
    data: {
        isOpen: true,
        title: 'Contact Us',
        address: '3165 E. Millrock Drive, Suite 100 Holladay, Utah 84121',
        time: <>Mon-Thu: 9:00 - 5:00 PM<br/> Fri: 9:00 - 3:00 PM</>,
        phone: '(385) 401-5200',
        mail: 'service@outcomepw.com',
        mapSource: MapImage
    },
    events: {
        handleClose: () => {}
    }
}