import { combineReducers } from 'redux';
import AccountDetailHoldingReducer from './AccountDetailHolding/AccountDetailHoldingReducer';
import AccountListReducer from './AccountList/AccountListReducer';
import AdminReducer from './Admin/AdminReducer';
import AssetAllocationDetailReducer from './AssetAllocationDetail/AssetAllocationDetailReducer';
import AuthReducer from './Auth/AuthReducer';
import MarketPerformanceReducer from './MarketPerformance/MarketPerformanceReducer';
import MarketWatchReducer from './MarketWatch/MarketWatchReducer';
import MoneyGuideReducer from './MoneyGuide/MoneyGuideReducer';
import NetWorthReducer from './Networth/NetWorthReducer';
import NetWorthDetailReducer from './NetWorthDetail/NetWorthDetailReducer';
import NetWorthPdfReducer from './NetWorthPdf/NetWorthPdfReducer';
import OutcomeGraphReducer from './OutcomeGraph/OutcomeGraphReducer';
import PageHeaderReducer from './PageHeader/PageHeaderReducer';
import UserPreferenceReducer from './UserPreference/UserPreferenceReducer';
import MoneyGuideSSOReducer from './MoneyGuideSSO/MoneyGuideSSOReducer';
import ChangePortfolioReducer from './ChangePortfolio/ChangePortfolioReducer';

const rootReducer = combineReducers({
    accountListReducer: AccountListReducer,
    authReducer: AuthReducer,
    pageHeaderReducer:PageHeaderReducer,
    accountDetailHoldingReducer:AccountDetailHoldingReducer,
    outcomeGraphReducer: OutcomeGraphReducer,
    adminReducer: AdminReducer,
    assetAllocationDetailReducer:AssetAllocationDetailReducer,
    marketWatchlistReducer: MarketWatchReducer,
    marketPerformanceReducer: MarketPerformanceReducer,
    moneyGuideReducer: MoneyGuideReducer,
    userPreferenceReducer:UserPreferenceReducer,
    netWorthReducer:NetWorthReducer,
    netWorthDetailReducer: NetWorthDetailReducer,
    netWorthPdfReducer:NetWorthPdfReducer,
    moneyGuideSSOReducer: MoneyGuideSSOReducer,
    changePortfolioReducer: ChangePortfolioReducer
});

export type RootStata = ReturnType<typeof rootReducer>

export default rootReducer;
