import { NetWorthDetailAction, NetWorthDetailActionType } from "./NetWorthDetail.action-types";

 
const INITIAL_STATE = {
  networthDetailGraph: {},
  networthHolding:[],
  isLoading: false,
  error: '',
  responseCode:200
}
 
const NetWorthDetailReducer = (state = INITIAL_STATE, action : NetWorthDetailAction) => {
  switch (action.type) {
    case NetWorthDetailActionType.GET_NETWORTH_DETAIL:
      return { ...state, isLoading: true };
    case NetWorthDetailActionType.GET_NETWORTH_DETAIL_SUCCESS:
      return { ...state, isLoading: false, networthDetailGraph: action.payload.netWorthGraph, networthHolding:action.payload.netWorthHoldings,error: "",  responseCode:200};
    case NetWorthDetailActionType.GET_NETWORTH_DETAIL_ERROR:
      return { ...state, isLoading: false, error: action.payload.errorMessage,responseCode:action.payload.responseCode };
    default:
      return { ...state };
  }
}
 
export default NetWorthDetailReducer;