import { call, put } from "redux-saga/effects";
import { fetchApi } from "../saga-index";
import { accountService, errMessage } from "../../../general-config";
import { getClientId, getCookies } from "../../../ui/utils/helper";
import { clientListSagaFormatter } from "./AdminScreenHelper";
import { getClientsError, getClientsSuccess } from "./Admin.actions";


export const clientListCall = () =>
  fetchApi(`${accountService}/clients`)

export const portfolioIdCall = (clientId:any) => fetchApi(`${accountService}/clients/${clientId}/primary-portfolio/current`)

export function* getClientListSaga(): any {
  try {
    const token = getCookies('access_token')
    if(token) {
      const response = yield call(clientListCall);//clientid: 8763221
      if(response?.data) {
        let formatted_data = clientListSagaFormatter(response.data);
        yield put(getClientsSuccess(formatted_data));
      }
      else 
      yield put(getClientsError(errMessage));
    }
  } catch (e : any) {
    yield put(getClientsError(e?.response?.data?.message || errMessage));
  }
}

export function* getClientPortfolioId(): any {
  try {
    const token = getCookies('access_token')
    let clientId : string = getClientId(token);
    if(token) {
      const response = yield call(portfolioIdCall, clientId);
      if(response?.data) {
        localStorage.setItem("portfolioId", response?.data?.id)
      }
    }
  } catch (e : any) {
    console.log(e?.response?.data?.message || errMessage)
  }
}