export const accountListHeaders = [
  { id: "symbol", label: "Symbol" },
  { id: "positionName", label: "Position Name" },
  { id: "portfolioPercent", label: "% of Portfolio" },
  { id: "marketValue", label: "Market Value" },
];

export const accountListContent = [
  {
    symbol: "AAPL",
    positionName: "Appl Inc.",
    portfolioPercent: 12.20,
    marketValue: 235.23,
    fullName:'Appli information'
  },
  {
    symbol: "SPPL",
    positionName: "Sppl Inc.",
    portfolioPercent: 2.20,
    marketValue: 225.23,
    fullName:'SPPL information'
  },
  {
    symbol: "AAPL",
    positionName: "Appl Inc.",
    portfolioPercent: 12.20,
    marketValue: 235.23,
    fullName:'Appl information'
  },
  {
    symbol: "AAPL",
    positionName: "Appl Inc.",
    portfolioPercent: 12.20,
    marketValue: 235.23,
    fullName:'Appl information'
  },
  {
    symbol: "AAPL",
    positionName: "Appl Inc.",
    portfolioPercent: 12.20,
    marketValue: 235.23,
    fullName:'Appl information'
  },
];

const holdingsData = [
  {
    holdingsTitle: "US Stock",
    accordionArray: [
      {
        accountName: "John Retirement",
        accountHolder:'OutcomePW',
        accountNumber:'****2222',
        showCta:true,
        accountListHeaders: accountListHeaders,
        accountListContent,
      },
      {
        accountName: "Susan Retirement",
        accountHolder:'Fidelity',
        accountNumber:'****2222',
        showCta:false,
        accountListHeaders: accountListHeaders,
        accountListContent,
      },
    ],
  },
  {
    holdingsTitle: "International Stock",
    accordionArray: [
      {
        accountName: "Sam Retirement",
        accountNumber:'****2222',
        accountHolder:'OutcomePW',
        showCta:true,
        accountListHeaders,
        accountListContent,
      },
      {
        accountName: "Rick Retirement",
        accountNumber:'****2222',
        accountHolder:'OutcomePW',
        showCta:true,
        accountListHeaders,
        accountListContent,
      },
    ],
  },
];

export const pdfHoldingsData = [
  {
    "accountName":"John Retirement",
    "accountNumber":"****0033",
    "data":[
      {
        "name":"Fixed Income",
        "value":0.0
      },
      {
        "name":"Equity",
        "value":0.0
      },
      {
        "name":"REITs",
        "value":0.0
      },
      {
        "name":"Cash Equivalents",
        "value":0.0
      },
      {
        "name":"Commodities",
        "value":0.0
      }
    ]
  },
  {
    "accountName":"Susan Retirement",
    "accountNumber":"****0022",
    "data":[
      {
        "name":"Fixed Income",
        "value":0.0
      },
      {
        "name":"Equity",
        "value":0.0
      },
      {
        "name":"REITs",
        "value":0.0
      },
      {
        "name":"Cash Equivalents",
        "value":0.0
      },
      {
        "name":"Commodities",
        "value":0.0
      }
    ]
  },
  {
    "accountName":"457",
    "accountNumber":"N/A",
    "data":[
      {
        "name":"US Equity",
        "value":86.51
      },
      {
        "name":"Unclassified",
        "value":0.0
      },
      {
        "name":"Non-US Equity",
        "value":11.78
      },
      {
        "name":"Unclassified",
        "value":1.7
      }
    ]
  },
  {
    "accountName":"Poorvi_Test_Account",
    "accountNumber":"N/A",
    "data":[
      {
        "name":"Unclassified",
        "value":4.22
      },
      {
        "name":"Unclassified",
        "value":95.78
      }
    ]
  },
  {
    "accountName":"Total Amount",
    "accountNumber":"N/A",
    "data":[

    ]
  },
  {
    "accountName":"Home",
    "accountNumber":"N/A",
    "data":[

    ]
  }
]

export const NetWorthHoldingsData = {
  cardTitle: "Holdings",
  holdingsArray: holdingsData,
  pdfHoldingsArray: pdfHoldingsData,
};


export const NetWorthDetailSampleResponse={
  "hasNetworth": true,
  "holdings": [
      {
          "className": "US Equity",
          "accounts": [
              {
                  "accountName": "457",
                  "accountNumber": "N/A",
                  "assets": [
                      {
                          "symbol": "AAPL",
                          "name": "Apple Inc",
                          "percentage": 86.51,
                          "marketValue": 17355.5
                      }
                  ]
              }
          ]
      },
      {
          "className": "Unclassified",
          "accounts": [
              {
                  "accountName": "457",
                  "accountNumber": "N/A",
                  "assets": [
                      {
                          "symbol": "TXLZF",
                          "name": "Tesla Exploration Ltd",
                          "percentage": 0.0,
                          "marketValue": 0.0
                      },
                      {
                          "symbol": "TWIN",
                          "name": "Twin Disc Inc",
                          "percentage": 1.7,
                          "marketValue": 342.0
                      }
                  ]
              },
              {
                  "accountName": "Poorvi_Test_Account",
                  "accountNumber": "N/A",
                  "assets": [
                      {
                          "symbol": "GLIIF",
                          "name": "Glacier Lake Resources Inc",
                          "percentage": 4.22,
                          "marketValue": 10.0
                      },
                      {
                          "symbol": "AMDWF",
                          "name": "AMADA Co Ltd",
                          "percentage": 95.78,
                          "marketValue": 227.0
                      }
                  ]
              }
          ]
      },
      {
          "className": "Non-US Equity",
          "accounts": [
              {
                  "accountName": "457",
                  "accountNumber": "N/A",
                  "assets": [
                      {
                          "symbol": "DOX",
                          "name": "Amdocs Ltd",
                          "percentage": 11.78,
                          "marketValue": 2364.0
                      }
                  ]
              }
          ]
      }
  ],
  "totalInvestmentAssets": 20298.5,
  "totalOtherAssets": 0.0,
  "totalAssets": 21498.5,
  "totalLiabilities": 3000.0,
  "totalNetworth": 18498.5,
  "totalMarginBalance": 0.0
}
