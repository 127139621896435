import { MarketWatchActionType } from "./MarketWatch.action-types";

//WATCH_LIST-----------------------------------
export const getMarketWatchlist = () => ({
    type: MarketWatchActionType.GET_MARKETWATCHLIST
});

export const getMarketWatchlistSuccess = (payload : any) => ({
    type: MarketWatchActionType.GET_MARKETWATCHLIST_SUCCESS,
    payload
});

export const getMarketWatchlistFailure = (error : any) => ({
    type: MarketWatchActionType.GET_MARKETWATCHLIST_ERROR,
    payload: error
})


//SEARCH_LIST------------------------------------
export const getMarketSearchList = (payload: string) => ({
    type: MarketWatchActionType.GET_MARKETSEARCHLIST,
    payload
})

export const getMarketSearchListSuccess = (payload: any) => ({
    type: MarketWatchActionType.GET_MARKETSEARCHLIST_SUCCESS,
    payload
})

export const getMarketSearchlistFailure = (error : any) => ({
    type: MarketWatchActionType.GET_MARKETSEARCHLIST_ERROR,
    payload: error
})

//Star/Unstar-----------------------------------
export const putStartUnstar = (payload:  {symbol: string, favorite: boolean}) => ({
    type: MarketWatchActionType.PUT_STAR_UNSTAR,
    payload
})

export const putStartUnstarSuccess = (payload: boolean) => ({
    type: MarketWatchActionType.PUT_STAR_UNSTAR_SUCCESS,
    payload
})

export const putStartUnstarFailure = (error : any) => ({
    type: MarketWatchActionType.PUT_STAR_UNSTAR_ERROR,
    payload: error
})
