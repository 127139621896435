import { GetMarketPerformanceAction, MarketPerformanceActionType } from "./MarketPerformance.action-types";

 
const INITIAL_STATE = {
  marketPerformanceData: [],
  isLoading: false,
  error: '',
}
 
const MarketPerformanceReducer = (state = INITIAL_STATE, action : GetMarketPerformanceAction) => {
  switch (action.type) {
    case MarketPerformanceActionType.GET_MARKET_PERFORMANCE:
      return { ...state, isLoading: true };
    case MarketPerformanceActionType.GET_MARKET_PERFORMANCE_SUCCESS:
      return { ...state, isLoading: false, marketPerformanceData: action.payload , error: ""};
    case MarketPerformanceActionType.GET_MARKET_PERFORMANCE_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return { ...state };
  }
}
 
export default MarketPerformanceReducer;