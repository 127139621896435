import Alert, { AlertColor } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { FC } from "react";
import { errMessage } from "../../../general-config";

interface AlertToastProps {
  open: boolean;
  onClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
  message: string;
  severity:AlertColor;
}
export const AlertToast: FC<AlertToastProps> = ({ open, onClose, message=errMessage,severity='error' }) => {
  const setClose = () => {
    if (onClose) onClose();
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={6000}
      onClose={setClose}
    >
      <Alert
        variant="filled"
        severity={severity}
        sx={{ width: "100%" }}
        onClose={setClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertToast;
