import { accountListHeaders } from "../../../ui/components/mock/NetWorthHolding";
import { AssetsResponse, AccountsResponse, NetWorthHoldingApiResponse } from "../../../ui/utils/ApiInterfaces/NetWorthHoldingsApi";


const getListContent = (data: AssetsResponse[]) => {
  let tempContent = {};
  let tempValue = 0;
  tempContent = data.map((item: AssetsResponse) => {
    tempValue = tempValue + item?.marketValue
      return {
        symbol: item?.symbol || "",
        positionName: item?.name?.substring(0, 7)?.trim() || "",
        portfolioPercent: item?.percentage
          ? Number(item.percentage.toFixed(2))
          : 0.0,
        marketValue: item?.marketValue
          ? Number(item.marketValue.toFixed(2))
          : 0.0,
        fullName: item?.name || "",
      }
     
  });
  let list = {
    content :tempContent , 
    totalMarketValue : tempValue ? Number(tempValue.toFixed(2)) : 0.0
  }
  return list;
};

const getAccordionData = (data: AccountsResponse[]) => {
  return data.map((item: AccountsResponse) => {
    let list = getListContent(item.assets)
    return {
      accountName: item.accountName,
      accountHolder: "OutcomePW",
      accountNumber: item.accountNumber,
      showCta: true,
      accountListHeaders: accountListHeaders,
      accountListContent: list.content,
      totalMarketValue : list.totalMarketValue
    };
  });
};

export const networthDetailHoldingFormatter = (
  data: NetWorthHoldingApiResponse[]
) => {
  let holdingsData = data.map((item: NetWorthHoldingApiResponse) => {
    return {
      holdingsTitle: item.className,
      accordionArray: getAccordionData(item.accounts),
    };
  });

  const newData = {
    cardTitle: "Holdings",
    holdingsArray: holdingsData,
  };
  return newData;
};
