
export enum  NetWorthActionType{
    GET_NETWORTH='GET_NETWORTH',
    GET_NETWORTH_SUCCESS='GET_NETWORTH_SUCCESS',
    GET_NETWORTH_ERROR='GET_NETWORTH_ERROR'
}

interface GetNetWorth{
  type:NetWorthActionType.GET_NETWORTH,
  payload:any,  
}

interface GetNetWorthSuccess{
    type:NetWorthActionType.GET_NETWORTH_SUCCESS,
    payload:any,  
  }
  interface GetNetWorthError{
    type:NetWorthActionType.GET_NETWORTH_ERROR,
    payload:any,  
  }

export type NetWorthAction=
| GetNetWorth
| GetNetWorthSuccess
| GetNetWorthError