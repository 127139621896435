export enum AccountDetailHoldingActionType {
    GET_ACCOUNTDETAIL_HOLDING = 'GET_ACCOUNTDETAIL_HOLDING',
    GET_ACCOUNTDETAIL_HOLDING_ERROR = 'GET_ACCOUNTDETAIL_HOLDING_ERROR',
    GET_ACCOUNTDETAIL_HOLDING_SUCCESS = 'GET_ACCOUNTDETAIL_HOLDING_SUCCESS'
  }
  interface GetHoldingData {
    type: AccountDetailHoldingActionType.GET_ACCOUNTDETAIL_HOLDING;
    payload?: any;
  }
  interface GetHoldingDataSuccess {
    type: AccountDetailHoldingActionType.GET_ACCOUNTDETAIL_HOLDING_SUCCESS;
    payload: any;
  }
  
  interface GetHoldingDataError {
    type: AccountDetailHoldingActionType.GET_ACCOUNTDETAIL_HOLDING_ERROR;
    payload: any;
  }
    
  export type GetAccountDetailHoldingAction =
    | GetHoldingData
    | GetHoldingDataSuccess
    | GetHoldingDataError;



