export const clientListSagaFormatter = ( data : []) => {
    let listData = {
        tableTitle: "Client Accounts",
        subTitle: "Look up Client",
        ctaLabel: "View as Client",
        portfolioTitle: "Change Portfolio",
        adminTransitionHeader: [
            { id: "firstName", label: "Client First Name" },
            { id: "lastName", label: "Client Last Name" },
            { id: "email", label: "Client Email" },
            { id: "status", label: "Status" },
            { id: "portfolioId", label: "" },
            { id: "action", label: "Actions" },
        ],
        adminTransitionContent : data
    }
    return listData;
}