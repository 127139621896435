export enum UserPreferenceActionType {
  GET_USER_PREFERENCE_ERROR = "GET_USER_PREFERENCE_ERROR",
  GET_USER_PREFERENCE = "GET_USER_PREFERENCE",
  GET_USER_PREFERENCE_SUCCESS = "GET_USER_PREFERENCE_SUCCESS",
  PUT_USER_PREFERENCE = "PUT_USER_PREFERENCE",
  PUT_USER_PREFERENCE_ERROR = "PUT_USER_PREFERENCE_ERROR",
  PUT_USER_PREFERENCE_SUCCESS = "PUT_USER_PREFERENCE_SUCCESS",
  SHOW_USER_PREFERENCE_TOAST="SHOW_NETWORTH_PDF_TOAST"
}
interface GetUserPreference {
  type: UserPreferenceActionType.GET_USER_PREFERENCE;
  payload?: any;
}
interface GetUserPreferenceSuccess {
  type: UserPreferenceActionType.GET_USER_PREFERENCE_SUCCESS;
  payload: any;
}

interface GetUserPreferenceError {
  type: UserPreferenceActionType.GET_USER_PREFERENCE_ERROR;
  payload: any;
}
interface PutUserPreference {
  type: UserPreferenceActionType.PUT_USER_PREFERENCE;
  payload: any;
}
interface PutUserPreferenceSuccess {
  type: UserPreferenceActionType.PUT_USER_PREFERENCE_SUCCESS;
  payload: any;
}

interface PutUserPreferenceError {
  type: UserPreferenceActionType.PUT_USER_PREFERENCE_ERROR;
  payload: any;
}

interface SetUserPreferenceToast{
  type:UserPreferenceActionType.SHOW_USER_PREFERENCE_TOAST,
  payload?:any,  
}
export type GetUserPreferenceAction =
  | GetUserPreference
  | GetUserPreferenceSuccess
  | GetUserPreferenceError
  | PutUserPreference
  | PutUserPreferenceSuccess
  | PutUserPreferenceError
  | SetUserPreferenceToast;
