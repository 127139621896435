import { ChangePortfolioAction, ChangePortfolioActionType } from "./ChangePortfolio.action-types";

const INITIAL_STATE = {
  portfolios: {
    "current": {},
    "portfolios": [{}]
  },
  isLoading: false, 
  error: "",
  toast: ""
};

const ChangePortfolioReducer = ( state = INITIAL_STATE, action: ChangePortfolioAction ) => {
  switch (action.type) {
    case ChangePortfolioActionType.GET_PORTFOLIO:
      return { ...state, isLoading: true , toast : "" };
    case ChangePortfolioActionType.GET_PORTFOLIO_SUCCESS:
      return { ...state, isLoading: false, portfolios: action.payload , error: "" , toast : ""};
    case ChangePortfolioActionType.GET_PORTFOLIO_ERROR:
      return { ...state, isLoading: false, error: action.payload ,toast:"Error in getting Portfolio" };
    case ChangePortfolioActionType.PUT_PORTFOLIO:
      return { ...state, isLoading: true  , toast : ""};
    case ChangePortfolioActionType.PUT_PORTFOLIO_SUCCESS:
      return { ...state, isLoading: false , error : ""  , toast : "Portfolio Changed Successfully" };
    case ChangePortfolioActionType.PUT_PORTFOLIO_ERROR:
      return { ...state, isLoading: false, error: action.payload, toast:"Error in changing PORTFOLIO" };
    default:
      return { ...state };
  }
};

export default ChangePortfolioReducer;
