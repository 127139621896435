import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */

type IdleTimer = {
  onIdle: () => void;
  idleTime: number;
};
const useIdleTimeout = (props: IdleTimer) => {
  const { onIdle, idleTime = 60000 } = props;
  const [isIdle, setIsIdle] = useState(false);
  const handleIdle = () => {
    setIsIdle(true);
  };

  const idleTimer= useIdleTimer({
    timeout: idleTime,
    onPrompt: onIdle,
    promptBeforeIdle: 1000,
    onIdle: handleIdle,
    startOnMount: true,
    crossTab: true,
    debounce: 500,
    syncTimers: 200,
    events:[           //removed visibilitychange event 
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ]
  });
  return {
    isIdle,
    setIsIdle,
    idleTimer
  };
};
export default useIdleTimeout;
