import React, { useState, useEffect, FC, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import { styled, useTheme } from "@mui/material/styles";
import { NavigationEntity, TopNavigationBarProps } from "./TopNavigationBar.type";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import { TransitionType } from "../../common/TopNavigationBarWrapper/TopNavigationBarWrapper.type";
import { AdminNavBar } from "../AdminNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledUserAccount } from "../../styles/common.styled";
import { getLogo, isNoHeader } from "../../../utils/helper";
import ThemeSwitch from "../../../utils/components/ThemeSwitch.component";
import { useSelector } from "react-redux";
const StyledContainer = styled(Container)(({ theme }) => {
  return {
    "&.MuiContainer-root": {
      padding: 0,
    },
  };
});
const StyledToolbar = styled(Toolbar)<TransitionType>(
  ({ theme, transition }) => {
    return {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "0px",
      gap: "24px",
      flex: "none",
      order: 0,
      flexGrow: 0,
      justifyContent: transition === "true" ? "center" : "flex-start",
    };
  }
);

const StyledContactUs = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "8px",
  };
});

const StyledExternalListBox = styled(Box)(({ theme }) => {
  return {
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "16px 24px",
    gap: "24px",
    position: "absolute",
    background: "#FFFFFF",
    boxShadow:
      "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
    borderRadius: "12px",
    marginTop: "12px",
    zIndex:1000,
    display: '-webkit-box',
    left: '32%'
  };
});

const StyledLink1 = styled(Link)(({ theme }) => {
  return {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    color: "#181A19",
    display: "flex",
    alignItems: "center",
  };
});

export const TopNavigationBar: FC<TopNavigationBarProps> = ({
  logo,
  navLinks,
  contactUsText,
  logOutText,
  externalLinkData,
  events,
  isTransition = false,
  isFirmUser = false,
}) => {
  const ref = useRef(null);
  const location = useLocation();
  const isFirmUserLanding = isFirmUser && location?.pathname === "/";
  const { handleContactUs, handleLogOut, handleSwitchToggle } = events;
  const [stickyClass, setStickyClass] = useState("relative");
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  let tempName = localStorage.getItem("clientName");
  const clientName = isFirmUser && tempName ? tempName : "";
  const theme = useTheme();

  const themeData = useSelector(
    (state: any) => state?.userPreferenceReducer?.themeData
  );

  const getAppBarBackground = () => {
    if (isFirmUserLanding && stickyClass !== "fixed") {
      return "transparent";
    } else if (isFirmUserLanding && stickyClass === "fixed") {
      return "#EEEFF1";
    } else if (stickyClass === "fixed") {
      return theme.palette.common.stickyNavBar;
    } else {
      return "transparent";
    }
  };
  const StyledAppBar = styled(AppBar)(({ theme }) => {
    return {
      background: getAppBarBackground(),
      boxShadow:
        stickyClass === "fixed"
          ? "0px 6px 10px 4px rgba(0, 0, 0, 0.09), 0px 2px 3px rgba(0, 0, 0, 0.1)"
          : "none",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "8px 32px",
      gap: "8px",
    };
  });
  const StyledButton1 = styled(Button)(({ theme }) => {
    return {
      fontFamily: "Noto Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: stickyClass === "fixed" ? theme.palette.text.primary : "#FFFFFF",
      textTransform: "none",
    };
  });

  const StyledLink = styled(Link)(({ theme }) => {
    return {
      fontFamily: "Noto Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      textTransform: "uppercase",
      color: stickyClass === "fixed" ? theme.palette.text.primary : "#FFFFFF",
    };
  });

  const StyledExternalLink = styled(StyledButton1)(({ theme }) => {
    return {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0px",
      textTransform: "uppercase",
      fontFamily: "Noto Sans",
      fontStyle: "normal",
      fontWeight: 600,
    };
  });

  const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)(({ theme }) => {
    return {
      transform: open ? "rotate(180deg)" : "",
    };
  });

  const StyledTypography = styled(Typography)(({ theme }) => {
    return {
      fontFamily: "Noto Sans, sans-serif",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "22px",
      lineHeight: "28px",
      color:
        stickyClass === "fixed"
          ? "#181A19"
          : theme.palette.primary.contrastText,
      paddingLeft: "24px",
    };
  });
const getLogoutColor=()=>{
  if (isFirmUserLanding && stickyClass != "fixed") {
    return '#FFFFFF';
  } else if (isFirmUserLanding && stickyClass === "fixed") {
    return '#181A19';
  } else if (
    !isFirmUserLanding &&
    stickyClass === "fixed"
  ) {
    return theme.palette.text.primary;
  } else {
    return '#FFFFFF';
  }
}
  const StyledLogout = styled(StyledButton1)(({ theme }) => {
    return {
      color: getLogoutColor(),
    };
  });
  const stickNavbar = () => {
    if (window !== undefined) {
      const windowHeight = document.body.scrollTop;
      windowHeight >= 50 ? setStickyClass("fixed") : setStickyClass("relative");
    }
  };

  useEffect(() => {
    document.body.addEventListener("scroll", stickNavbar);
    return () => {
      document.body.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const onClickOutside = () => {
    setOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && ref.current !== null && ref.current !== event.target) {
        onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);
  const handleExternalLink = () => {
    setOpen(!open);
  };

  const StyledDivider=styled(Divider)(({theme})=>{
    return {
     borderColor: 'rgba(0, 0, 0, 0.12)',
    }
  })

  return (
    <>
      {isFirmUser && !isFirmUserLanding && !isNoHeader() && (
        <AdminNavBar
          clientName={clientName}
          onCtaBackClick={() => navigate("/")}
        />
      )}
      <StyledAppBar
        position="static"
        sx={{
          position: stickyClass === "fixed" ? "sticky" : "static",
          top: 0,
          zIndex: 100,
        }} //to make navbar sticky
      >
        <StyledContainer maxWidth="xl">
          <StyledToolbar disableGutters transition={isTransition.toString()}>
            <Box>
              <Link aria-label="tnb" href={logo.link} underline="none">
                <img
                  src={getLogo(isFirmUserLanding,stickyClass, logo, themeData)}
                  width="128px"
                  height="49px"
                  alt={logo?.link}
                />
                {/* 40px previous height */}
              </Link>
            </Box>
            {isFirmUserLanding && (
              <Box sx={{ display: "flex" }}>
                <StyledDivider orientation="vertical" flexItem />
                <StyledTypography>Advisor</StyledTypography>
              </Box>
            )}
            {!isTransition && !isFirmUserLanding && (
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex", gap: "24px" },
                }}
              >
                {navLinks.map((page:NavigationEntity, index:number) => (
                  <div key={page.label + `${index}`}>
                    {page?.externalLink ? (
                      <div>
                        <StyledExternalLink
                          ref={ref}
                          onClick={handleExternalLink}
                          data-testid="external-link"
                        >
                          {page.label}
                          <StyledArrowDropDownIcon />
                        </StyledExternalLink>
                        {open && (
                          <StyledExternalListBox>
                            {externalLinkData?.navLinks?.map((page:NavigationEntity) => (
                              <StyledLink1
                                underline="none"
                                href={page.link}
                                key={page.label}
                                target={page.target}
                              >
                                <img
                                  src={page?.image ?? ""}
                                  alt={page?.label}
                                  // style={{ marginRight: "10px" }}
                                  height={53}
                                  width={98}
                                />
                              </StyledLink1>
                            ))}
                          </StyledExternalListBox>
                        )}
                      </div>
                    ) : (
                      <StyledLink
                        underline="none"
                        href={page.link}
                        key={page.label}
                        target={page.target}
                      >
                        {page.label}
                      </StyledLink>
                    )}
                  </div>
                ))}
              </Box>
            )}
            {!isTransition && (
              <StyledUserAccount
                width={isFirmUserLanding ? "100%" : "auto"}
                justifyContent={isFirmUserLanding ? "flex-end" : "center"}
              >
                {!isFirmUserLanding && (
                  <StyledContactUs>
                    <StyledButton1
                      startIcon={<ContactPageOutlinedIcon />}
                      onClick={handleContactUs}
                      data-testid="contact-us"
                    >
                      {contactUsText}
                    </StyledButton1>
                  </StyledContactUs>
                )}
                <StyledLogout
                  startIcon={<LogoutIcon />}
                  onClick={handleLogOut}
                  data-testid="logout"
                >
                  {logOutText}
                </StyledLogout>
                {!isFirmUserLanding && (
                  <ThemeSwitch
                    handleToggle={handleSwitchToggle}
                  />
                )}
              </StyledUserAccount>
            )}
          </StyledToolbar>
        </StyledContainer>
      </StyledAppBar>
    </>
  );
};
export default TopNavigationBar;
