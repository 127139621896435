import React, { useState, useEffect, FC } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Drawer from "@mui/material/Drawer";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminNavBar } from "../AdminNavBar";
import {
  TopNavigationBarMobileProps,
  TransitionTypeMobileProps,
} from "../TopNavigationBarMobile";
import {
  StyledCloseIcon,
  StyledContainer,
  StyledLink,
  StyledMenuBox,
  StyledTopIconBox,
  StyledUserAccount,
} from "../../styles/common.styled";
import { emptyObject, isNoHeader } from "../../../utils/helper";
import ThemeSwitch from "../../../utils/components/ThemeSwitch.component";
import { useSelector } from "react-redux";
import ExternalDrawer from "../TopNavigationBarMobile/ExternalDrawer.component";
import { NavigationEntity } from "../TopNavigationBar/TopNavigationBar.type";

const StyledToolbarMobile = styled(Toolbar)<TransitionTypeMobileProps>(
  ({ theme, transition }) => {
    return {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "0px",
      gap: "24px",
      flex: "none",
      order: 0,
      flexGrow: 0,
      justifyContent: transition === "true" ? "center" : "space-between",
    };
  }
);

const StyledBoxMobile = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

const StyledExternalLink = styled(StyledLink)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px",
    width: "100%",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.text.primary,
    letterSpacing: "0.15px",
  };
});

const StyledDrawer = styled(Drawer)(({ theme }) => {
  return {
    "& .MuiDrawer-paper": {
      width: "350px",
      backgroundColor: theme.palette.common.drawerBackground,
    },
  };
});

const StyledLinkNew = styled(Link)(({ theme }) => {
  return {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.text.primary,
    width: "100%",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.15px",
  };
});


const StyledKeyboardArrowRightIcon = styled(KeyboardArrowRightIcon)(
  ({ theme }) => {
    return {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    };
  }
);
const StyledContactUs = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "8px",
  };
});

export const TopNavigationBarSmallDesktop: FC<TopNavigationBarMobileProps> = ({
  logo,
  navLinks,
  contactUsText,
  logOutText,
  externalLinkData,
  events,
  isTransition = false,
  isFirmUser = false,
}) => {
  const { handleContactUs, handleSwitchToggle, handleLogOut } = events;
  const [isSidebar, setIsSidebar] = useState(false);
  const [stickyClass, setStickyClass] = useState("relative");
  const [isExternalDrawer, setIsExternalDrawer] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let tempName = localStorage.getItem("clientName");
  const isFirmUserLanding =
    isFirmUser && (location?.pathname === "/" || isNoHeader());
  const clientName = isFirmUser && tempName ? tempName : "";
  const themeData = useSelector(
    (state:any) =>  state?.userPreferenceReducer?.themeData);
  const StyledAppBar = styled(AppBar)(({ theme }) => {
    return {
      background:
        stickyClass === "fixed"
          ? theme.palette.common.stickyNavBar
          : "transparent",
      boxShadow:
        stickyClass === "fixed"
          ? "0px 6px 10px 4px rgba(0, 0, 0, 0.09), 0px 2px 3px rgba(0, 0, 0, 0.1)"
          : "none",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "8px 32px",
      gap: "8px",
    };
  });

  const StyledButton = styled(Button)(({ theme }) => {
    return {
      fontFamily: "Noto Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: stickyClass === "fixed" ? theme.palette.text.primary : "#FFFFFF",
      textTransform: "none",
    };
  });
  const StyledIconButton = styled(IconButton)(({ theme }) => {
    return {
      color: stickyClass === "fixed" ? theme.palette.text.primary : "#FFFFFF",
    };
  });

  const stickNavbar = () => {
    if (window !== undefined) {
      const windowHeight = document.body.scrollTop;
      windowHeight >= 50 ? setStickyClass("fixed") : setStickyClass("relative");
    }
  };
  const onIconClick = () => {
    setIsSidebar(!isSidebar);
  };
  useEffect(() => {
    document.body.addEventListener("scroll", stickNavbar);

    return () => {
      document.body.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const onBackArrowClick = () => {
    setIsExternalDrawer(false);
  };
  const handleExternalLink = () => {
    setIsExternalDrawer(true);
  };
  return (
    <>
      {isFirmUser && !isFirmUserLanding && (
        <AdminNavBar
          clientName={clientName}
          onCtaBackClick={() => navigate("/")}
        />
      )}
      <StyledAppBar
        position="static"
        sx={{
          position: stickyClass === "fixed" ? "sticky" : "static",
          top: 0,
          zIndex: 100,
        }}
      >
        <StyledContainer maxWidth="xl">
          <StyledToolbarMobile
            disableGutters
            transition={isTransition.toString()}
          >
            <StyledBoxMobile>
              {!isTransition && (
                <StyledIconButton
                  size="large"
                  edge="start"
                  aria-label="menu"
                  onClick={onIconClick}
                  data-testid="menu-icon"
                >
                  <MenuOutlinedIcon />
                </StyledIconButton>
              )}
              <Box>
                <Link
                  aria-label="tnb-small-desktop"
                  href={logo.link}
                  underline="none"
                >
                  <img
                    src={
                      stickyClass === "fixed" && themeData?.find((f:any)=> f?.type === 'themeMode') && !emptyObject(themeData?.find((f:any)=> f?.type === 'themeMode' && f?.value === 'light'))
                        ? logo?.colorLogoUrl
                        : logo?.whiteLogoUrl
                    }
                    width="128px"
                    height="49px"
                    alt={logo?.link}
                  />
                  {/* 40px previous height */}
                </Link>
              </Box>
            </StyledBoxMobile>
            <Box>
              {!isTransition && (
                <StyledUserAccount
                  width={isFirmUserLanding ? "100%" : "auto"}
                  justifyContent={isFirmUserLanding ? "flex-end" : "center"}
                >
                  {!isFirmUserLanding && (
                    <StyledContactUs>
                      <StyledButton
                        startIcon={<ContactPageOutlinedIcon />}
                        onClick={handleContactUs}
                        data-testid="contact-us"
                      >
                        {contactUsText}
                      </StyledButton>
                    </StyledContactUs>
                  )}
                  <StyledButton
                    startIcon={<LogoutIcon />}
                    onClick={handleLogOut}
                    data-testid="logout"
                  >
                    {logOutText}
                  </StyledButton>
                  {!isFirmUserLanding && (
                    <ThemeSwitch  handleToggle={handleSwitchToggle} />
                  )}
                </StyledUserAccount>
              )}
            </Box>
          </StyledToolbarMobile>
        </StyledContainer>
      </StyledAppBar>

      <StyledDrawer
        anchor="left"
        open={isSidebar}
        onClose={() => setIsSidebar(false)}
        data-testid="mobile-drawer"
      >
        <Box>
          <StyledTopIconBox>
            <StyledCloseIcon
              size="large"
              edge="start"
              aria-label="close"
              onClick={onIconClick}
              data-testid="close-icon"
            >
              <CloseIcon />
            </StyledCloseIcon>
          </StyledTopIconBox>
          {!isFirmUserLanding && (
            <StyledMenuBox>
              {navLinks.map((page:NavigationEntity, index: number) => (
                <div key={page.label + `${index}`} style={{ width: "100%" }}>
                  {page?.externalLink ? (
                    <StyledExternalLink
                      underline="none"
                      href="#"
                      onClick={handleExternalLink}
                      data-testid="external-link"
                      target={page.target}
                    >
                      {page.label}
                      <StyledKeyboardArrowRightIcon />
                    </StyledExternalLink>
                  ) : (
                    <StyledLinkNew
                      underline="none"
                      href={page.link}
                      target={page.target}
                    >
                      {page.label}
                    </StyledLinkNew>
                  )}
                </div>
              ))}
            </StyledMenuBox>
          )}
        </Box>
      </StyledDrawer>
      <ExternalDrawer  isExternalDrawer={isExternalDrawer} onBackArrowClick={onBackArrowClick}  externalLinkData={externalLinkData}  drawerCloseEvent={()=>setIsExternalDrawer(false)}/>
    </>
  );
};
export default TopNavigationBarSmallDesktop;
