import { call, put } from "redux-saga/effects";
import { fetchApi, mgpPortfolio } from "../saga-index";
import { errMessage, mgpService } from "../../../general-config";
import { getCookies } from "../../../ui/utils/helper";
import { getNetWorthPdfSuccess, getNetWorthPdfError } from "./NetWorthPdf.actions";

export const networthPdfCall = (portfolioId: string) =>
  fetchApi(`${mgpService}/networth/pdf?portfolioId=${portfolioId}`);

export const getSeverity = (data: any) => {
  if (data.length === 0) {
    return "warning";
  }  else {
    return "error";
  }
};

const getSeverityMessage = (data: any, message: string|undefined) => {
  if (data.length === 0) {
    return "No Data Available";
  } else {
    return message ?? errMessage;
  }
};

export function* getNetWorthPdfSaga(): any {
  try {
    const token = getCookies("access_token");
    if (token) {
      const portfolioId = yield call(mgpPortfolio);
      if (portfolioId) {
        const response = yield call(networthPdfCall, portfolioId);
        if (response?.data && response?.data.length > 0) {
          yield put(getNetWorthPdfSuccess(response?.data));
        } else {
          yield put(
            getNetWorthPdfError({
              message: getSeverityMessage(
                response?.data,
                response?.data?.message
              ),
              severity: getSeverity(response?.data),
            })
          );
        }
      }
      else {
        yield put(getNetWorthPdfError({message: errMessage,severity:'error'}));
      }
    }
  } catch (e: any) {
    yield put(getNetWorthPdfError({message:e?.response?.data?.message || errMessage,severity:'error'}));
  }
}
