import { AccountDetailHoldingActionType, GetAccountDetailHoldingAction } from "./AccountDetailHolding.action-types";

 
const INITIAL_STATE = {
  holdingList: {},
  isLoading: false,
  error: '',
}
 
const AccountDetailHoldingReducer = (state = INITIAL_STATE, action : GetAccountDetailHoldingAction) => {
  switch (action.type) {
    case AccountDetailHoldingActionType.GET_ACCOUNTDETAIL_HOLDING:
      return { ...state, isLoading: true};
    case AccountDetailHoldingActionType.GET_ACCOUNTDETAIL_HOLDING_SUCCESS:
      return { ...state, isLoading: false, holdingList: action.payload };
    case AccountDetailHoldingActionType.GET_ACCOUNTDETAIL_HOLDING_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return { ...state };
  }
}
 
export default AccountDetailHoldingReducer;