export enum MoneyGuideActionType {
    GET_MONEYGUIDE_IMAGE = "GET_MONEYGUIDE_IMAGE",
    GET_MONEYGUIDE_IMAGE_SUCCESS = "GET_MONEYGUIDE_IMAGE_SUCCESS",
    GET_MONEYGUIDE_IMAGE_ERROR = "GET_MONEYGUIDE_IMAGE_ERROR",
  }
  
  interface GetMoneyGuideImage {
    type: MoneyGuideActionType.GET_MONEYGUIDE_IMAGE;
    payload?: any;
  }
  interface GetMoneyGuideImageSuccess {
    type: MoneyGuideActionType.GET_MONEYGUIDE_IMAGE_SUCCESS;
    payload: any;
  }
  
  interface GetMoneyGuideImageError {
    type: MoneyGuideActionType.GET_MONEYGUIDE_IMAGE_ERROR;
    payload: any;
  }
  
  export type GetMoneyGuideAction =
    | GetMoneyGuideImage
    | GetMoneyGuideImageSuccess
    | GetMoneyGuideImageError;
  