import { NetWorthDetailActionType } from "./NetWorthDetail.action-types";


export const getNetWorthDetail=()=>({
    type:NetWorthDetailActionType.GET_NETWORTH_DETAIL
});

export const getNetWorthDetailSuccess =(payload:any)=>({
    type:NetWorthDetailActionType.GET_NETWORTH_DETAIL_SUCCESS,
    payload
})

export const getNetWorthDetailError =(payload:any)=>({
    type:NetWorthDetailActionType.GET_NETWORTH_DETAIL_ERROR,
    payload
})