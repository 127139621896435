import { NetWorthPdfActionType } from "./NetWorthPdf.action-types";


export const getNetWorthPdf=()=>({
    type:NetWorthPdfActionType.GET_NETWORTH_PDF
});

export const getNetWorthPdfSuccess =(payload:any)=>({
    type:NetWorthPdfActionType.GET_NETWORTH_PDF_SUCCESS,
    payload
})

export const getNetWorthPdfError =(payload:any)=>({
    type:NetWorthPdfActionType.GET_NETWORTH_PDF_ERROR,
    payload
})

export const setNetWorthPdfToast =()=>({
    type:NetWorthPdfActionType.SHOW_NETWORTH_PDF_TOAST,
})