import * as React from "react";
import { styled } from "@mui/material/styles";
import { BackgroundProps } from "./Background.type";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
const image = require("./Loader.webp");

const getBackgroundImage = (isFirmUserLanding: boolean, theme: string) => {
  if (isFirmUserLanding) {
    return `url(${image})`;
  } else if (!isFirmUserLanding && theme === "light") {
    return `url(${image})`;
  } else {
    return `linear-gradient(0deg, rgba(25, 25, 26, 0.5), rgba(25, 25, 26, 0.5)),url(${image})`;
  }
};

const BgPageWrapper = styled(({ isFirmUserLanding, ...rest }: any) => (
  <Box {...rest} />
))(({ theme, isFirmUserLanding }) => {
  return {
    [theme.breakpoints.down('md')]:{
      backgroundImage: getBackgroundImage(isFirmUserLanding, theme.palette.mode),
      backgroundSize: "cover",
      width: "100%",
      height: "100%",
      minHeight: "100vh",
      zIndex: "-1",
    }
  };
});

export const Background = ({
  children,
  transition = false,
  isFirmUser,
}: BackgroundProps) => {
  const location = useLocation();
  const isFirmUserLanding = isFirmUser && location?.pathname === "/";

  return (
    <BgPageWrapper
      data-testid="bg-image"
      isFirmUserLanding={isFirmUserLanding}
    >
      {children}
    </BgPageWrapper>
  );
};
export default Background;
