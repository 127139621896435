import { GetOutcomeGraphAction, OutcomeGraphActionType } from "./OutcomeGraph.action-types";

 
const INITIAL_STATE = {
  outcomeGraph: {},
  isLoading: false,
  error: '',
}
 
const OutcomeGraphReducer = (state = INITIAL_STATE, action : GetOutcomeGraphAction) => {
  switch (action.type) {
    case OutcomeGraphActionType.GET_OUTCOMEGRAPH:
      return { ...state, isLoading: true };
    case OutcomeGraphActionType.GET_OUTCOMEGRAPH_SUCCESS:
      return { ...state, isLoading: false, outcomeGraph: action.payload , error: ""};
    case OutcomeGraphActionType.GET_OUTCOMEGRAPH_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return { ...state };
  }
}
 
export default OutcomeGraphReducer;