import { useTheme, styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import React, { FC } from "react";
import { PageHeaderProps, StyleProps } from "./PageHeader.type";
import ArrowBack from "@mui/icons-material/ArrowBack";

const StyledBox = styled(Box)(({ theme }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return {
    display: isMobile ? "block" : "flex",
    justifyContent: "space-between",
    padding: isMobile ? "16px 16px 0px 16px" : "32px 32px 0px 32px",
  };
});

const StyledTypography = styled(Typography)<StyleProps>(
  ({ theme, fontSize }) => {
    return {
      fontWeight: 600,
      fontSize: fontSize,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "#FFF",
      fontFamily: "Noto Sans",
      fontStyle: "normal",
    };
  }
);

const StyledTitleTypography = styled(Typography)(({ theme }) => {
  return {
    fontWeight: 600,
    fontSize: "36px",
    lineHeight: "44px",
    color: "#FFF",
    fontFamily: "Lato",
    fontStyle: "normal",
    wordBreak: "break-word",
  };
});

const StyledDivider = styled(Divider)(({ theme }) => {
  return {
    backgroundColor: "#FFF",
    margin: "0px 20px 0px 24px !important",
  };
});
const StyledIcon = styled(ArrowBack)(() => {
  return {
    color: "#FFF",
  };
});

const StyledDisclaimerTypography = styled(Typography)(({ theme }) => {
  return {
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "16px",
    letterSpacing: "0.5px",
    fontFamily: "Lato",
    fontStyle: "normal",
    color: "#C5CACE",
  };
});

const StyledSubtitleTypography = styled(Typography)(({ theme }) => {
  return {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: theme.palette.common.white,
    paddingTop: "8px",
  };
});
export const PageHeader: FC<PageHeaderProps> = ({
  title = "",
  subTitle = "",
  dividerText = "",
  icon = false,
  preTitle = "",
  disclaimer,
  onIconClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const StyledInnerBox = styled(Box)(({ theme }) => {
    return {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      [theme.breakpoints.down(900)]: {
        flexDirection: "column",
      },
    };
  });
 
  return (
    <StyledBox sx={{display:`${title==="Net Worth" ? "none" : ""}`}}>
      <StyledInnerBox>
        {preTitle && (
          <StyledTypography fontSize={16} data-testid="preTitle">
            {preTitle}
          </StyledTypography>
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {icon && (
              <IconButton onClick={onIconClick}>
                <StyledIcon data-testid="back-arrow" />
              </IconButton>
            )}
            {title && (
              <StyledTitleTypography data-testid="title">
                {title}
              </StyledTitleTypography>
            )}
            {!isMobile && dividerText && (
              <>
                <StyledDivider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                <StyledTypography fontSize={12} data-testid="subTitle">
                  {dividerText}
                </StyledTypography>
              </>
            )}
          </Stack>
          {subTitle && (
            <StyledSubtitleTypography fontSize={12} data-testid="subTitle">
              {subTitle}
            </StyledSubtitleTypography>
          )}
          {disclaimer && (
            <StyledDisclaimerTypography data-testid="disclaimer">
              {disclaimer}
            </StyledDisclaimerTypography>
          )}
        </Box>

        {isMobile && dividerText && (
          <StyledTypography
            sx={{ paddingLeft: "54px" }} //32px previous
            fontSize={12}
            data-testid="divider"
          >
            {dividerText}
          </StyledTypography>
        )}
      </StyledInnerBox>
    </StyledBox>
  );
};
export default PageHeader;
