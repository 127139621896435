export enum MarketPerformanceActionType {
    GET_MARKET_PERFORMANCE = "GET_MARKET_PERFORMANCE",
    GET_MARKET_PERFORMANCE_SUCCESS = "GET_MARKET_PERFORMANCE_SUCCESS",
    GET_MARKET_PERFORMANCE_ERROR = "GET_MARKET_PERFORMANCE_ERROR",
  }
  
  interface GetMarketPerformance {
    type: MarketPerformanceActionType.GET_MARKET_PERFORMANCE;
    payload?: any;
  }
  interface GetMarketPerformanceSuccess {
    type: MarketPerformanceActionType.GET_MARKET_PERFORMANCE_SUCCESS;
    payload: any;
  }
  
  interface GetMarketPerformanceError {
    type: MarketPerformanceActionType.GET_MARKET_PERFORMANCE_ERROR;
    payload: any;
  }
  
  export type GetMarketPerformanceAction =
    | GetMarketPerformance
    | GetMarketPerformanceSuccess
    | GetMarketPerformanceError;
  