import { call, put } from "redux-saga/effects";
import { accountService, errMessage } from "../../../general-config";
import { getCookies, getClientId } from "../../../ui/utils/helper";
import { fetchApi, fetchPutApi } from "../saga-index";
import { getPortfolioFailure, getPortfolioSuccess, putPortfolioFailure, putPortfolioSuccess } from "./ChangePortfolio.actions";
import { getPortfolioHelper } from "./ChangePortfolioHelper";

export const getPortfolioCall = (clientId: string) =>
  fetchApi(`account-service/v1/clients/${clientId}/primary-portfolios`);

export function* getPortfolioSaga(): any {
  try {
    const token = getCookies("access_token");
    if (token) {
      let clientId: string = getClientId(token);
      if (clientId) {
        const response = yield call(getPortfolioCall, clientId); //clientid: 8763221
        if (response?.data) {
          let temp = getPortfolioHelper(response.data)
          yield put(getPortfolioSuccess(temp));
        }
      } else {
        yield put(getPortfolioFailure(errMessage));
      }
    }
  }
  catch (e: any) {
    yield put(
      getPortfolioFailure(e?.response?.data?.message || errMessage)
    );
  }
}

export const PORTFOLIOPutCall = (clientId: string, value : any) =>
  fetchPutApi(`${accountService}/clients/${clientId}/primary-portfolio/current`, value);

export function* putPortfolioSaga(payload: any): any {
  try {
    const token = getCookies("access_token");
    if (token) {
      let clientId: string = getClientId(token);
      if (clientId) {
        const response = yield call(
          PORTFOLIOPutCall,
          clientId,
          payload.payload
        );
        if (response.data) {
          if(response.data.status === "OK") {
            yield put(putPortfolioSuccess());
          }
        } else {
          yield put(putPortfolioFailure(errMessage));
        }
      }
    }
  } catch (e: any) {
    yield put(
      putPortfolioFailure(e?.response?.data?.message || errMessage)
    );
  }
}