import { call, put } from "redux-saga/effects";
import { fetchApi, mgpPortfolio } from "../saga-index";
import { errMessage, mgpService } from "../../../general-config";
import { getClientEmail, getCookies } from "../../../ui/utils/helper";
import { getMoneyGuideSSOFailure, getMoneyGuideSSOSuccess } from "./MoneyGuideSSO.actions";


export const moneyGuideSSOCall = (portfolioId : string , email : string) =>
  fetchApi(`${mgpService}/sso?portfolioId=${portfolioId}&email=${email}`)

export function* getMoneyGuideSSOSaga(): any {
  try {
    const token = getCookies('access_token');
    const portfolioId = yield call(mgpPortfolio);
    const email = getClientEmail(token);
    if(email && portfolioId) {
      const response = yield call(moneyGuideSSOCall, portfolioId, email)
      if(response.data) {
        yield put(getMoneyGuideSSOSuccess(response.data));
      }
    }
    else {
      yield put(getMoneyGuideSSOFailure(errMessage));
    }
  } catch (e : any) {
    yield put(getMoneyGuideSSOFailure(e?.response?.data?.message || errMessage));
  }
}