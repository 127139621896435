export enum OutcomeGraphActionType {
  GET_OUTCOMEGRAPH = "GET_OUTCOMEGRAPH",
  GET_OUTCOMEGRAPH_ERROR = "GET_OUTCOMEGRAPH_ERROR",
  GET_OUTCOMEGRAPH_SUCCESS = "GET_OUTCOMEGRAPH_SUCCESS",
}

interface GetOutcomeGraph {
  type: OutcomeGraphActionType.GET_OUTCOMEGRAPH;
  payload?: any;
}
interface GetOutcomeGraphSuccess {
  type: OutcomeGraphActionType.GET_OUTCOMEGRAPH_SUCCESS;
  payload: any;
}

interface GetOutcomeGraphError {
  type: OutcomeGraphActionType.GET_OUTCOMEGRAPH_ERROR;
  payload: any;
}

export type GetOutcomeGraphAction =
  | GetOutcomeGraph
  | GetOutcomeGraphSuccess
  | GetOutcomeGraphError;
