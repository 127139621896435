import { MarketPerformanceActionType } from "./MarketPerformance.action-types";

export const getMarketPerformance = () => ({
    type: MarketPerformanceActionType.GET_MARKET_PERFORMANCE
});

export const getMarketPerformanceSuccess = (payload : any) => ({
    type: MarketPerformanceActionType.GET_MARKET_PERFORMANCE_SUCCESS,
    payload
});

export const getMarketPerformanceFailure = (error : any) => ({
    type: MarketPerformanceActionType.GET_MARKET_PERFORMANCE_ERROR,
    payload: error
})