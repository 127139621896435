import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  tnbData,
  tnbDataAsClient,
} from "./ui/components/mock/TopNavigationBar";

import { useDispatch, useSelector } from "react-redux";
import { TopNavigationBarWrapper } from "./ui/components/common/TopNavigationBarWrapper";

import {
  backHandlerEvents,
  checkAndOperatorValidity,
  checkNestedTernaryValidity,
  checkOrOperatorValidity,
  emptyObject,
  getBackgroundImage,
  getCookies,
  handleLogoutFn,
  isNoHeader,
  logoutAction,
  navToSessionExpired,
} from "./ui/utils/helper";
import { contactModalMock } from "./ui/components/mock/ContactModal";
import ProtectedUserRoute from "./ui/components/routes/ClientRoute";
import ProtectedRoute from "./ui/components/routes/AdminRoute";
import { getRequest } from "./api/api-config";
import ScrollToTop from "./ui/utils/components/ScrollToTop";
import SessionExpiredPopup from "./ui/utils/components/SessionExpiredPopup.component";
import useIdleTimeout from "./ui/utils/hooks/useIdleTimer";
import { MAXWIDTH, errMessage, idleTime, isTestingMode } from "./general-config";
import "./index.css";
import { darkTheme, lightTheme } from "./ui/themes";
import { GraphWidthContext } from "./app-state/context/graphWidthContext";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AlertToast from "./ui/utils/components/AlertToast.component";
import { LoadingScreen } from "./ui/components/organisms/LoadingScreen";
import { PageHeader } from "./ui/components/organisms/PageHeader";
import { ContactModal } from "./ui/components/organisms/ContactModal";
import { setResponse } from "./app-state/store/AccountList/AccountListSagaHelpers";
import { getUserPreference, putUserPreference, setUserPreferenceToast } from "./app-state/store/UserPreference/UserPreferences.actions";
import { GlobalStyles } from "@mui/material";
import { Background } from "./ui/components/organisms/Background";
import { getPortfolioId } from "./app-state/store/Admin/Admin.actions";

const Dashboard = React.lazy(() =>
  import(/* webpackChunkName: "Dashboard" */ "./pages/Dashboard").then(({ Dashboard }) => ({ default: Dashboard }))
);
const AccountDetail = React.lazy(() =>
  import(/* webpackChunkName: "AccountDetail" */ "./pages/AccountDetail").then(({ AccountDetail }) => ({
    default: AccountDetail,
  }))
);
const AssetAllocation = React.lazy(() =>
  import(/* webpackChunkName: "AssetAllocation" */ "./pages/AssetAllocation").then(({ AssetAllocation }) => ({
    default: AssetAllocation,
  }))
);
const NetWorth = React.lazy(() =>
  import(/* webpackChunkName: "NetWorth" */ "./pages/NetWorth").then(({ NetWorth }) => ({ default: NetWorth }))
);
const AdminScreen = React.lazy(() =>
  import(/* webpackChunkName: "AdminScreen" */ "./pages/AdminScreen").then(({ AdminScreen }) => ({
    default: AdminScreen,
  }))
);
const Transition = React.lazy(() =>
  import(/* webpackChunkName: "Transition" */ "./pages/Transition").then(({ Transition }) => ({
    default: Transition,
  }))
);

const App = () => {
  const HeaderData = useSelector((store: any) => store?.pageHeaderReducer);
  const themeData = useSelector(
    (state: any) => state?.userPreferenceReducer?.themeData
  );
  const userPrefData = useSelector(
    (state: any) => state?.userPreferenceReducer
  );

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    dispatch(setUserPreferenceToast());
  };

  const isUserPrefLoading = useSelector(
    (state: any) => state?.userPreferenceReducer?.isLoading
  );
  const dispatch = useDispatch();
  const [contactModalOpen, setContactModalOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [firmUser, setFirmUser] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [checkMode, setCheckMode] = useState(false);
  const pathname = window?.location?.pathname;
  const [graphWidth, setGraphWidth] = useState<number>(0);
  const handleIdle = () => {
    if (getCookies("access_token")) {
      logoutAction();
      setAlertOpen(true);
    }
  };
  const {
    isIdle
  } = useIdleTimeout({
    onIdle: handleIdle,
    idleTime: parseInt(idleTime),
  });
  console.log(isIdle);
  const setSession = (session: any) => {
    let tempUser = setResponse(session);
    setFirmUser(tempUser);
    checkUserPreference();
    dispatch(getPortfolioId())
  };
  
  const checkUserPreference = (isFirm: boolean = false) => {
    if (isFirm && pathname === "/") {
      setLoading(false);
    } else {
      dispatch(getUserPreference());
      setCheckMode(true);
    }
  };

  useEffect(() => {
    if (!isUserPrefLoading && checkMode) setLoading(false);
  }, [isUserPrefLoading]);

  const getAccessTokenInTestingMode = async (userId: any) => {
    try {
      const response = await getRequest(
        `auth-service/token/test-user?userId=${userId}`
      );
      setSession(checkOrOperatorValidity(response?.data, ""));
    } catch (error: any) {
      navToSessionExpired(error);
    }
  };

  const initSession = async (code: any) => {
    try {
      const response = await getRequest(`auth-service/token?code=${code}`);
      setSession(checkOrOperatorValidity(response?.data, ""));
    } catch (error: any) {
      navToSessionExpired(error);
    }
  };

  const setRole = () => {
    let role = checkOrOperatorValidity(localStorage.getItem("role"), "");
    let temp = checkNestedTernaryValidity(role === "FirmUser", true, false);
    setFirmUser(temp);
    checkUserPreference(temp);
    dispatch(getPortfolioId());
  };

  const setUser = (token: string) => {
    if (token) setRole();
    else {
      setLoading(false);
      setTransition(true);
    }
  };

  const initFunc = async () => {
    let query = new URLSearchParams(window.location.search);
    let userId = query?.get("UserId");
    console.log("isTestingMode", isTestingMode);
    if (checkAndOperatorValidity(isTestingMode.toString() === "true", userId)) {
      await getAccessTokenInTestingMode(userId);
    } else {
      let code = checkOrOperatorValidity(query?.get("code"), "");
      let token = getCookies("access_token");
      if (checkAndOperatorValidity(code, !token)) {
        window.history.replaceState({}, document.title, "/");
        await initSession(code);
      } else {
        setUser(token);
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      await initFunc();
      backHandlerEvents();
    })();
    return () => {
      window.removeEventListener("popstate", () => {});
      window.removeEventListener("pageshow", () => {});
    };
  }, []);

  const handleHeaderBackArrow = () => {
    window.location.href = checkNestedTernaryValidity(
      firmUser,
      "/clientDashboard?scroll=accountdetail",
      "/?scroll=accountdetail"
    );
  };

  const handlePdf = () => {
    console.log("pdf");
  };

  const handleSwitchToggle = (data: any) => {
    dispatch(putUserPreference({ type: "themeMode", value: data }));
  };

  return checkNestedTernaryValidity(
    loading,
    <LoadingScreen />,

    <ThemeProvider
      theme={checkNestedTernaryValidity(
        !emptyObject(
          themeData?.find(
            (f: any) => f?.type === "themeMode" && f?.value === "dark"
          )
        ),
        darkTheme,
        lightTheme
      )}
    >
      <GlobalStyles 
       styles={(theme) => ({ 
        body: { 
          backgroundImage : getBackgroundImage(firmUser && window?.location?.pathname === "/", theme.palette.mode),
          backgroundSize : "cover"
        }
      })}/>
      <GraphWidthContext.Provider value={{ graphWidth, setGraphWidth }}>
        <BrowserRouter>
          <ScrollToTop />
          <Background
            isFirmUser={firmUser}
            transition={transition || isNoHeader()}
          >
            <TopNavigationBarWrapper
              isTransition={transition || isNoHeader()}
              isFirmUser={firmUser}
              data={{
                navLinks: checkNestedTernaryValidity(
                  firmUser,
                  tnbDataAsClient,
                  tnbData.navLinks
                ),
                contactUsText: tnbData.contactUsText,
                logo: tnbData.logo,
                logOutText: tnbData.logOutText,
                externalLinkData: tnbData.externalLinkData,
              }}
              events={{
                handleContactUs: () => setContactModalOpen(true),
                handleSwitchToggle: handleSwitchToggle,
                handleLogOut: () => handleLogoutFn(),
              }}
            />
            <Box sx={{ maxWidth: `${MAXWIDTH}px`, margin: "0 auto" }}>
              <PageHeader
                title={HeaderData?.data?.title}
                subTitle={HeaderData?.data?.subTitle}
                dividerText={HeaderData?.data?.dividerText}
                icon={HeaderData?.data?.icon}
                disclaimer={HeaderData?.data?.disclaimer}
                onIconClick={handleHeaderBackArrow}
                onPdfClick={handlePdf}
              />
              <React.Suspense fallback={<LoadingScreen />}>
                <Routes>
                  <Route
                    path="/"
                    element={checkNestedTernaryValidity(
                      transition,
                      <Transition />,
                      checkNestedTernaryValidity(
                        firmUser,
                        <AdminScreen />,
                        <Dashboard />
                      )
                    )}
                  />
                  <Route
                    path="/"
                    element={<ProtectedUserRoute alertOpen={alertOpen} />}
                  >
                    <Route path="/account/:id" element={<AccountDetail />} />
                    <Route path="/asset" element={<AssetAllocation />} />
                    <Route path="/networth" element={<NetWorth />} />
                  </Route>
                  <Route
                    path="/"
                    element={<ProtectedRoute alertOpen={alertOpen} />}
                  >
                    <Route path="/clientDashboard" element={<Dashboard />} />
                    <Route
                      path="/clientAccount/:id"
                      element={<AccountDetail />}
                    />
                    <Route path="/clientAsset" element={<AssetAllocation />} />
                    <Route path="/clientNetworth" element={<NetWorth />} />
                  </Route>
                  <Route
                    path="/sessionExpired"
                    element={<Transition page="expired" />}
                  />
                  <Route
                    path="/logout"
                    element={<Transition page="logout" />}
                  />
                  <Route path="/404" element={<Transition page="404" />} />
                  <Route path="/*" element={<Navigate to="/404" />} />
                </Routes>
              </React.Suspense>
              <ContactModal
                data={{ ...contactModalMock.data, isOpen: contactModalOpen }}
                events={{ handleClose: () => setContactModalOpen(false) }}
              />
              <AlertToast
                open={userPrefData?.toast}
                severity="error"
                message={userPrefData?.error || errMessage}
                onClose={handleClose}
              />
            </Box>
          </Background>
          <SessionExpiredPopup open={alertOpen} />
        </BrowserRouter>
      </GraphWidthContext.Provider>
    </ThemeProvider>
  );
};

export default App;
