
export enum  NetWorthPdfActionType{
    GET_NETWORTH_PDF='GET_NETWORTH_PDF',
    GET_NETWORTH_PDF_SUCCESS='GET_NETWORTH_PDF_SUCCESS',
    GET_NETWORTH_PDF_ERROR='GET_NETWORTH_PDF_ERROR',
    SHOW_NETWORTH_PDF_TOAST="SHOW_NETWORTH_PDF_TOAST"
}

interface GetNetWorthPdf{
  type:NetWorthPdfActionType.GET_NETWORTH_PDF,
  payload:any,  
}

interface GetNetWorthPdfSuccess{
    type:NetWorthPdfActionType.GET_NETWORTH_PDF_SUCCESS,
    payload:any,  
  }
  interface GetNetWorthPdfError{
    type:NetWorthPdfActionType.GET_NETWORTH_PDF_ERROR,
    payload:any,  
  }
  interface SetNetWorthPdfToast{
    type:NetWorthPdfActionType.SHOW_NETWORTH_PDF_TOAST,
    payload?:any,  
  }

export type NetWorthPdfAction=
| GetNetWorthPdf
| GetNetWorthPdfSuccess
| GetNetWorthPdfError
| SetNetWorthPdfToast