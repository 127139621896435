import { NetWorthPdfAction, NetWorthPdfActionType } from "./NetWorthPdf.action-types";

 
const INITIAL_STATE = {
  pdfData: {},
  isLoading: false,
  error: '',
  toast:false,
  severity:'error'
} 
const NetWorthPdfReducer = (state = INITIAL_STATE, action : NetWorthPdfAction) => {
  switch (action.type) {
    case NetWorthPdfActionType.GET_NETWORTH_PDF:
      return { ...state, isLoading: true ,toast:false};
    case NetWorthPdfActionType.GET_NETWORTH_PDF_SUCCESS:
      return { ...state, isLoading: false, pdfData: action.payload , error: "",toast:false};
    case NetWorthPdfActionType.GET_NETWORTH_PDF_ERROR:
      return { ...state, isLoading: false, error: action?.payload?.message,toast:true,severity:action?.payload?.severity };
      case NetWorthPdfActionType.SHOW_NETWORTH_PDF_TOAST:
        return {...state,isLoading: false,toast:false}
    default:
      return { ...state };
  }
}
 
export default NetWorthPdfReducer;