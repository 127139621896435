import { GetMoneyGuideSSOAction, MoneyGuideSSOActionType } from "./MoneyGuideSSO.action-types";

const INITIAL_STATE = {
  moneyGuideSAML: '',
  isLoading: false,
  error: '',
  responseCode:200
}
 
const MoneyGuideSSOReducer = (state = INITIAL_STATE, action : GetMoneyGuideSSOAction) => {
  switch (action.type) {
    case MoneyGuideSSOActionType.GET_MONEYGUIDE_SSO:
      return { ...state, isLoading: true };
    case MoneyGuideSSOActionType.GET_MONEYGUIDE_SSO_SUCCESS:
      return { ...state, isLoading: false, moneyGuideSAML: action.payload , error: "",responseCode:200};
    case MoneyGuideSSOActionType.GET_MONEYGUIDE_SSO_ERROR:     
      return { ...state, isLoading: false, error: action.payload.errorMessage,responseCode:action.payload.responseCode};
    default:
      return { ...state };
  }
}
 
export default MoneyGuideSSOReducer;