import { AuthActionType } from "./Auth.action-types";

export const refreshAuth = () => ({
    type: AuthActionType.REFRESH_AUTH,
});

export const refreshAuthSuccess = (payload : any) => ({
    type: AuthActionType.REFRESH_AUTH_SUCCESS,
    payload
});

export const refreshAuthFailure = (error : any) => ({
    type: AuthActionType.REFRESH_AUTH_FAILURE,
    payload: error
})

