import { AssetAllocationDetailActionType } from "./AssetAllocationDetail.action-types";

export const getAssetAllocationDetail = () => ({
  type: AssetAllocationDetailActionType.GET_ASSETALLOCATION_DETAIL,
  
});

export const getAssetAllocationDetailSuccess = (payload : any) => ({
  type: AssetAllocationDetailActionType.GET_ASSETALLOCATION_DETAIL_SUCCESS,
  payload
});

export const getAssetAllocationDetailError = (error : any) => ({
  type: AssetAllocationDetailActionType.GET_ASSETALLOCATION_DETAIL_ERROR,
  payload: error
})