import { ChangePortfolioActionType } from "./ChangePortfolio.action-types";

export const getPortfolio = () => ({
    type: ChangePortfolioActionType.GET_PORTFOLIO
});

export const getPortfolioSuccess = (payload : any) => ({
    type: ChangePortfolioActionType.GET_PORTFOLIO_SUCCESS,
    payload
});

export const getPortfolioFailure = (error : any) => ({
    type: ChangePortfolioActionType.GET_PORTFOLIO_ERROR,
    payload: error
})


export const putPortfolio = (payload : any) => ({
    type: ChangePortfolioActionType.PUT_PORTFOLIO,
    payload
})

export const putPortfolioSuccess = () => ({
    type: ChangePortfolioActionType.PUT_PORTFOLIO_SUCCESS,
});

export const putPortfolioFailure = (error : any) => ({
    type: ChangePortfolioActionType.PUT_PORTFOLIO_ERROR,
    payload: error
});