import { UserPreferenceActionType } from "./UserPreferences.action-types";

export const getUserPreference = () => ({
    type: UserPreferenceActionType.GET_USER_PREFERENCE
});

export const getUserPreferenceSuccess = (payload : any) => ({
    type: UserPreferenceActionType.GET_USER_PREFERENCE_SUCCESS,
    payload
});

export const getUserPreferenceFailure = (error : any) => ({
    type: UserPreferenceActionType.GET_USER_PREFERENCE_ERROR,
    payload: error
})


export const putUserPreference = (error : any) => ({
    type: UserPreferenceActionType.PUT_USER_PREFERENCE,
    payload: error
})

export const putUserPreferenceSuccess = (payload : any) => ({
    type: UserPreferenceActionType.PUT_USER_PREFERENCE_SUCCESS,
    payload
});

export const putUserPreferenceFailure = (error : any) => ({
    type: UserPreferenceActionType.PUT_USER_PREFERENCE_ERROR,
    payload: error
})

export const setUserPreferenceToast = () => ({
    type:UserPreferenceActionType.SHOW_USER_PREFERENCE_TOAST,
});