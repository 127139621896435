import { ColorPalette } from "./colorTheme.type";

const  darkPallete : ColorPalette = {
    palette: {
        mode:"dark",
        primary: {
            main: '#FFFFFF',
            light: "rgba(0, 150, 94, 0.2)", //grren background
            contrastText: "#FFF"
        },
        text: {
            primary: "#FFFFFF",
            disabled: "#7B827F",
            secondary: "#FFFFFF",
        },
        error: {
            main: "#F23645",
            light: "rgba(190, 31, 40, 0.22)"
        },
        common: {
            mainBackground: "linear-gradient(0deg, rgba(83, 96, 108, 0.05), rgba(83, 96, 108, 0.05)), rgba(25, 25, 26, 0.8)",
            lightBackground: "linear-gradient(0deg, rgba(83, 96, 108, 0.05), rgba(83, 96, 108, 0.05)), #333333",
            hint: "rgba(246, 247, 248, 0.85)",
            viewCta:'#00965E',
            marketSymbol:'#FFFFFF',
            marketAmount:'#FFFFFF',
            searchBar:'linear-gradient(0deg, rgba(83, 96, 108, 0.05), rgba(83, 96, 108, 0.05)), #333333;',
            searchBarOpen:'linear-gradient(0deg, rgba(83, 96, 108, 0.05), rgba(83, 96, 108, 0.05)), #333333;',
            marketWatchHeaders:'#FFFFFF',
            zeroBackground:'rgba(83, 96, 108, 0.19)',
            zeroText:'#FFFFFF',
            greenText:'#00965E',
            accountListBackground:'linear-gradient(0deg, rgba(83, 96, 108, 0.05), rgba(83, 96, 108, 0.05)), rgba(25, 25, 26, 0.8);',
            marketSymbolMobile:'rgba(246, 247, 248, 0.85)',
            drawerBackground:'#181A19',
            switchThumbBackground:'#FFFFFF',
            switchTrackBackground:'#221C35 !important',
            switchTrackBorder:"1px solid #FFFFFF",
            marketWatchSearchIcon:'#181A19',
            searchIcon:'#FFFFFF',
            searchDrawerText:'7B827F',
            divider:'#FFFFFF',
            stickyNavBar:'#181A19',
            contactModal:'#353536',
            graphBackground:'#181A19',
            graphDrawer:'#121212',
            graphDrawerContent:'transparent',
            accordionBorderBottom: 'transparent !important',
            accordionBgColor: 'transparent !important',
            contactDrawer: "#353536",
            searchTooltipBG: '#000000',
            searchTooltipFont: '#FFFFFF',
            errorCard: "#FFFFFF",
            errorBg: "transparent"
        },
    },
    typography: {
        label: {

        },
        title: {
            fontSize: 45
        }
    }
}

export default darkPallete;