export enum AuthActionType {
    REFRESH_AUTH = "REFRESH_AUTH",
    REFRESH_AUTH_SUCCESS = "REFRESH_AUTH_SUCCESS",
    REFRESH_AUTH_FAILURE = "REFRESH_AUTH_FAILURE"
}
interface SetAuth {
    type: AuthActionType.REFRESH_AUTH;
    payload?: any;
}
interface SetAuthSuccess {
    type: AuthActionType.REFRESH_AUTH_SUCCESS;
    payload: any;
}
  
interface SetAuthError {
    type: AuthActionType.REFRESH_AUTH_FAILURE;
    payload: any;
}
    
export type AuthAction =
    | SetAuth
    | SetAuthSuccess
    | SetAuthError;