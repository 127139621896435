import { styled } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import TableSortLabel from "@mui/material/TableSortLabel";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { UserAccountStyles } from "../common/TopNavigationBarWrapper/TopNavigationBarWrapper.type";
import CircularProgress from "@mui/material/CircularProgress";

export const StyledCard = styled(Card)(({ theme }) => {
  return {
    background: theme.palette.common.mainBackground,
    borderRadius: "8px",
    marginBottom: "16px",
  };
});

export const LoadingStyledCard = styled(StyledCard)(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center", 
    minHeight: "400px",
    padding: '0 16px'
  };
});

export const StyledCardHeader = styled(CardHeader)(({ theme }) => {
  return {
    "& .MuiTypography-root": {
      fontFamily: "Noto Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      color: theme.palette.text.secondary,
    },
    "& .MuiCardHeader-subheader": {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 600,
      paddingTop: "16px"
    }
  };
});

export const StyledCardAction = styled(CardActions)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "16px",
  };
});

export const StyledButton = styled(Button)(({ theme }) => {
  return {
    color:  theme.palette.primary.main,
    textTransform: "none",
  };
});

export const SeeMoreStyledButton = styled(StyledButton)(({ theme }) => {
  return {
    padding: "0px"
  };
});

export const StyledTableCellTitle = styled(TableCell)(({ theme }) => {
  return {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: " 0.15px",
    color: theme.palette.common.hint,
    borderBottom: "1px solid #7B827F",
    '&.MuiTableCell-root':{
      whiteSpace:'nowrap',
    }
  };
});

export const CustomizedTableCellTitle=styled(StyledTableCellTitle)(({theme})=>{
  return {
    padding:'16px 20px 16px 16px'           //previous 8px 
  }
})

export const StyledTableCellContent = styled(TableCell)(({ theme }) => {
  return {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: " 0.15px",
    color: theme.palette.text.primary,
    borderBottom: "none",
    whiteSpace:'nowrap',
    padding: "16px 20px 16px 16px"
  };
});

export const PdfTableCellContent = styled(StyledTableCellContent)(({ theme }) => {
  return {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "16px",
    letterSpacing: " 0.15px",
    color: "#181A19",
    borderBottom: "none",
    whiteSpace:'nowrap',
  };
});

export const StyledAccordion = styled(Accordion)(({ theme }) => {
  return {
    "&.MuiAccordion-root": {
      borderBottom: `1px solid ${theme.palette.text.disabled}`,
      backgroundColor: "rgba(0,0,0,0)",
      borderRadius: 0,
      padding: "8px 0px",
    },
    "&:last-child": {
      borderBottom: "none",
    },
  };
});

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => {
  return {
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
});

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => {
  return {
    borderTop: "1px solid #7B827F",
    padding: "8px 0px 0px",
  };
});

export const StyledTableSortLabel=styled(TableSortLabel)<any>(({theme , isnumeric})=>{
  return {
    width: isnumeric === "true" ? `0px` : "40px", 
    height: isnumeric === "true" ? `0px` : "40px",
    "& .MuiIconButton-root":{
      color:  theme.palette.text.primary,
      position: isnumeric === "true" ? `absolute` : "static", 
      right: isnumeric === "true" ? `-35px` : "auto"
    }
  }
});
export const StyledHoldingsTitle = styled(Typography)(({ theme }) => {
  return {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "28px",
    color: theme.palette.text.secondary,
  };
});

export const StyledHoldingsSubTitle = styled(Typography)(({ theme }) => {
  return {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  };
});

export const StyledHoldingsRightTitle = styled(Typography)(({ theme }) => {
  return {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.5px",
    color: theme.palette.common.hint,
    [theme.breakpoints.down('md')]:{
      paddingLeft: "32px",
      paddingBottom: "15px" 
    }
  };
});

export const CustomisedHoldingAccordionSummary=styled(StyledAccordionSummary)(({theme})=>{
  return {
    "&.MuiAccordionSummary-root": {
      padding: 0,
    },
  }
})

export const StyledTemplateBox=styled(Box)(({theme})=>{
  return {
    padding:'16px 32px 0px 32px',
    [theme.breakpoints.down('md')]:{
      padding:'16px 16px 0px 16px',
    }
  }
})
export const StyledBox = styled(Box)(({ theme }) => {
  return {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    borderBottom: "1px solid #7B827F",
    "&:last-child": {
      borderBottom: "none",
    },
  };
});

export const StyledBoxTypography = styled(Typography)(({ theme }) => {
  return {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
  };
});

export const StyledAccountTypographyBox = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "16px",
  };
});

export const StyledAccountTypography = styled(Typography)(({ theme }) => {
  return {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: theme.palette.text.secondary,
  };
});

export const StyledUserAccount = styled(Box)<UserAccountStyles>(({ theme , width , justifyContent }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: justifyContent,
    alignItems: "center",
    padding: "0px",
    gap: "32px",
    width: width
  };
});
export const StyledContainer = styled(Container)(({ theme }) => {
  return {
    "&.MuiContainer-root": {
      padding: 0,
    },
  };
});

export const StyledCloseIcon = styled(IconButton)(({ theme }) => {
  return {
    "&.MuiButtonBase-root": {
      marginLeft: '24px',
      marginTop: "24px",
      color: theme.palette.text.primary,
      justifyContent: "flex-start",
      padding:0
    },
  };
});

export const DragIconButton = styled(IconButton)(({ theme }) => {
  return {
    cursor: "grab",
    '&:active': {cursor: 'grabbing'}
  };
});

export const StyledMenuBox = styled(Box)(({ theme }) => {
  return {
    display: "grid",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px 24px",
    gap: "48px",
    paddingTop: "53px",
    paddingLeft: "24px",
  };
});
export const StyledLink = styled(Link)(({ theme }) => {
  return {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "32px",
    color: theme.palette.text.primary,
    width:'100%',
    display: "flex", 
    alignItems: "center"
  };
});

export const StyledEmptyData = {
  color: '#53606C',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  fontSize: '14px',
  fontFamily: 'Noto Sans',
  fontWeight: 600
}
export const StyledTypography = styled(Typography)(({ theme }) => {
  return {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: theme.palette.text.primary,
    padding: "52px 24px 48px 24px",
  };
});

export const StyledTopIconBox = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  }
});

export const ChartWrapper = styled(Box)<any>(({theme}) => {
  return {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
    "& .recharts-yAxis .recharts-text": {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: "12px",
      fill:theme.palette.text.primary,
    },
  };
});
export const CustomisedPagination=styled(TablePagination)(({theme})=>{
  return {
    "& .MuiTablePagination-selectIcon":{
      color:'rgba(0, 0, 0, 0.54)'
    },
    "& .MuiTablePagination-actions .MuiButtonBase-root.Mui-disabled":{
      color:'rgba(0, 0, 0, 0.26)'
    },
  }
});
export const StyledCircularProgress=styled(CircularProgress)(({theme})=>{
  return {
    color :'#FFFFFF',

  }
})

export const StyledDisclaimerTypography = styled(Typography)(({ theme }) => {
  return {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: "0.4px",
    color: "#FFFFFF",
    "&:last-child": {
      marginBottom: 0,
    },
  };
});

export const StyledDisclaimerBox = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "32px",
    gap: "8px",
    background:
      "linear-gradient(0deg, rgba(83, 96, 108, 0.05), rgba(83, 96, 108, 0.05)), rgba(25, 25, 26, 0.8)",
    marginTop: 32,
  };
});