import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getCookies } from "../../utils/helper";
import { RouteType } from "./route.types";

const  ProtectedUserRoute = ({alertOpen=false}:RouteType) => {
   const getClient = () => {
    if (alertOpen) {
      return true;
    } 
    else if (token && localStorage.getItem("role")!=="FirmUser") {
      return true;
    } 
    else 
      return null;
  };
    const token = getCookies("access_token") || "";
    const isClient = getClient();          
    return isClient ? 
    <Outlet /> : 
    <Navigate to="/" />;
}
export default ProtectedUserRoute;