import { AccountListActionType } from "./AccountList.action-types";

export const getAccountList = () => ({
    type: AccountListActionType.GET_ACCOUNTLIST
});

export const getAccountListSuccess = (payload : any) => ({
    type: AccountListActionType.GET_ACCOUNTLIST_SUCCESS,
    payload
});

export const getAccountListFailure = (error : any) => ({
    type: AccountListActionType.GET_ACCOUNTLIST_ERROR,
    payload: error
})

