import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import { checkNestedTernaryValidity, ifFirmUser } from "../helper";
const darkLogo = require("../../images/darkmode.webp");
const lightLogo = require("../../images/lightmode.webp");

const StyledSwitch = styled(Switch)(({ theme }) => {
  return {
    "&.MuiSwitch-root": {
      display: "flex",
      alignItems: "center",
      height: "24px",
      width: "52px",
      padding: 0,
      border: theme.palette.common.switchTrackBorder,
      [theme.breakpoints.down(900)]: {
        marginTop: "24px",
        marginRight: "24px",
      },
      borderRadius: "100px",
      "& .MuiSwitch-switchBase": {
        padding: "0px",
        margin: '1.8px', //2px
        [theme.breakpoints.down(900)]: { 
          margin: "2px",
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        backgroundColor: theme.palette.common.switchThumbBackground,
        width: "16px",
        height: "16px",
        margin: 1,
        marginLeft: theme.palette.mode==='dark'?'8px':'2px',
        marginRight: theme.palette.mode==='dark'?'2px':'8px',
        borderRadius: "100px",
      },
      "& .MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":{
        background:'#221C35 !important'
       },
      "& .MuiSwitch-track": {
        background: theme.palette.common.switchTrackBackground,
        opacity: "inherit !important",
        borderRadius: "100px",
        "&:before, &:after": {
          content: '""',
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          width: 16,
          height: 16,
        },
        "&:before": {
          backgroundImage: `url('${darkLogo}')`,
          left: 5,
          backgroundSize: "12px 12px",
          backgroundRepeat: " no-repeat",
          backgroundPosition: "center",
        },
        "&:after": {
          backgroundImage: `url('${lightLogo}')`,
          right: "3px",
          backgroundSize: "15px 15px",
          paddingRight: "5px",
          backgroundRepeat: " no-repeat",
          backgroundPosition: "center",
        },
      },
    },
  };
});
type ThemeSwitchType={
  handleToggle:((theme:string) => void)
}
export const ThemeSwitch = (props: ThemeSwitchType) => {
  const { handleToggle } = props;

  const [checked, setChecked] = useState(checkNestedTernaryValidity(localStorage.getItem("themeMode") === "dark", true , false));
  

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    handleToggle(event.target.checked ? "dark" : "light");
  };

  return <StyledSwitch   disabled={ifFirmUser()}  checked={checked} onChange={handleChange} />;
};

export default ThemeSwitch;
