import axios from 'axios';
import { apiDomain, requestTimeout} from '../general-config';
import { getCookies } from '../ui/utils/helper';

const apiInstance = axios.create({
    baseURL: apiDomain,
    timeout: requestTimeout,
});

apiInstance.interceptors.request.use(
    (config) => {
      const AUTH_TOKEN = getCookies("access_token");
      if (!config.headers['Authorization']) {
        Object.assign(config.headers, {
          'Authorization': `${AUTH_TOKEN}`
        });
      }
      return config;
    },
    (error) => Promise.reject(error),
);

export default apiInstance;




