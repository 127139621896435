import { NetWorthMock } from "../../../ui/components/mock/NetWorth";
import { LegendData } from "../../../ui/components/organisms/NetWorth/NetWorth.type";
import { NetWorthGraphApiResponse } from "../../../ui/utils/ApiInterfaces";
import { getToFixed } from "../../../ui/utils/helper";


const getUV = (data: NetWorthGraphApiResponse, name: string) => {
  if (name === "Assets") {
    return data.totalInvestmentAssets;
  } else if (name === "Liabilities") {
    return 0;
  } else if (name === "Net worth") {
    return 0;
  }
};

const getPV = (data: NetWorthGraphApiResponse, name: string) => {
  if (name === "Assets") {
    return data.totalOtherAssets;
  } else if (name === "Liabilities") {
    return -data.totalLiabilities;
  } else if (name === "Net worth") {
      return data.totalNetworth
   
  }
};

const getChartData = (data: NetWorthGraphApiResponse) => {
  return NetWorthMock.data.chartData.map((item: any) => {
    return {
      name: item.name,
      uv: getUV(data, item.name),
      pv: getPV(data, item.name), 
      pvFill: item.pvFill,
      uvFill: item.uvFill,
    };
  });
};

const getLegendAmount = (data: NetWorthGraphApiResponse, name: string) => {
  if (name == "Investment Assets") {
    return `$${getToFixed(data.totalInvestmentAssets)}`;
  } else if (name === "Other Assets") {
    return `$${getToFixed(data.totalOtherAssets)}`;
  } else if (name === "Total  Assets") {
    return `$${getToFixed(data.totalAssets)}`;
  } else if (name === "Liabilities") {
    if(data.totalLiabilities===0){
      return `$${getToFixed(data.totalLiabilities)}`;
    };
    return `($${getToFixed(data.totalLiabilities)})`;
  } else if (name === "Net Worth") {
    if(data.totalNetworth<0){
      const amount = -data.totalNetworth;
      return  `(${new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',}).format(amount) })`
    }
    return  new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      }).format(data.totalNetworth)
    }
};

const getLegendData = (data: NetWorthGraphApiResponse) => {
  return NetWorthMock.data.legendData.map((item: LegendData) => {
    return {
      svgColor: item?.svgColor ? item.svgColor : "",
      name: item.name,
      amount:getLegendAmount(data, item.name),
    };
  });
};

export const networthGraphFormatter = (data: NetWorthGraphApiResponse) => {
  return {
    title: "Net Worth",
    chartData: getChartData(data),
    legendData: getLegendData(data),
    btnText: "See More",
  };
};


