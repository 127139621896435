import apiInstance from ".";

export function getRequest(URL : string) {
    return apiInstance.get(`/${URL}`)
    .then(response => response)
    .catch((error) => {
        throw error
    });
}

export function postRequest(URL: string, payload : any) {
    return apiInstance.post(`/${URL}`, payload).then(response => response);
}

export function patchRequest(URL: string, payload : any) {
    return apiInstance.patch(`/${URL}`, payload).then(response => response);
}

export function putRequest(URL: string, payload : any) {
    return apiInstance.put(`/${URL}`, payload).then(response => response);
}

export function deleteRequest(URL: string, payload : any) {
    return apiInstance.delete(`/${URL}`).then(response => response);
}