import { OutcomeGraphActionType } from "./OutcomeGraph.action-types";

export const getOutcomeGraph = () => ({
    type: OutcomeGraphActionType.GET_OUTCOMEGRAPH
});

export const getOutcomeGraphSuccess = (payload : any) => ({
    type: OutcomeGraphActionType.GET_OUTCOMEGRAPH_SUCCESS,
    payload
});

export const getOutcomeGraphFailure = (error : any) => ({
    type: OutcomeGraphActionType.GET_OUTCOMEGRAPH_ERROR,
    payload: error
})