import React, {FC} from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AdminNavBarType } from "./AdminNavBar.type";


const StyledContainerBlue = styled(Container)(({ theme }) => {
  return {
    padding: "11px 28px",
    background: "#221C35",
    color: theme.palette.primary.contrastText, 
    display: "flex", 
    alignItems: "center",
    maxWidth: "100% !important"
  };
});

const StyledBox = styled(Box)(({ theme }) => {
  return {
    
    display: "flex", 
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginLeft: "-6px"
  };
});

const StyledCloseIcon = styled(IconButton)(({ theme }) => {
  return {
    "&.MuiButtonBase-root": {
      marginLeft: 0,
      paddingRight: "6px",
      paddingLeft: "0px",
      color: theme.palette.primary.contrastText,
      justifyContent: "flex-start",
    },
  };
});
const StyledTypography=styled(Typography)(({theme})=>{
  return {
    wordBreak: 'break-word'
  }
})
export const AdminNavBar: FC<AdminNavBarType> = ({
  clientName,
  onCtaBackClick
}) => {
  return (
    <StyledContainerBlue>
      <StyledCloseIcon
        edge="start"
        aria-label="back"
        onClick={onCtaBackClick}
        data-testid="back-icon"
      >
        <ArrowBackIcon />
      </StyledCloseIcon>
      <StyledBox>
        <StyledTypography data-testid="clientNameText">
          Viewing as : {clientName}
        </StyledTypography>
      </StyledBox>
    </StyledContainerBlue>
  );
};

export default AdminNavBar;