import { MoneyGuideSSOActionType } from "./MoneyGuideSSO.action-types";

export const getMoneyGuideSSO = () => ({
    type: MoneyGuideSSOActionType.GET_MONEYGUIDE_SSO
});

export const getMoneyGuideSSOSuccess = (payload : any) => ({
    type: MoneyGuideSSOActionType.GET_MONEYGUIDE_SSO_SUCCESS,
    payload
});

export const getMoneyGuideSSOFailure = (error : any) => ({
    type: MoneyGuideSSOActionType.GET_MONEYGUIDE_SSO_ERROR,
    payload: error
})