export const assetListHeaders = [
  { id: "symbol", label: "Symbol" },
  { id: "positionName", label: "Position Name" },
  { id: "marketValue", label: "Market Value" },
  { id: "portfolioPercent", label: "% of Portfolio" },
];

const tableData = [
  {
    symbol: "AAPL",
    positionName: "Appl Inc.",
    marketValue: 222.21,
    portfolioPercent: 3.21,
  },
  {
    symbol: "MSFT",
    positionName: "Microsoft Inc.",
    marketValue: 21.22,
    portfolioPercent: 34.21,
  },
  {
    symbol: "AMZN",
    positionName: "Amazon Inc.",
    marketValue: 9.87,
    portfolioPercent: 24.01,
  },
  {
    symbol: "TSLA",
    positionName: "Tesla Inc.",
    marketValue: 12.50,
    portfolioPercent: 4.21,
  },
];
const tableData1 = [
    {
      symbol: "SBI",
      positionName: "SBI Inc.",
      marketValue: 10.15,
      portfolioPercent: 45.1,
    },
    {
      symbol: "FT",
      positionName: "FT Inc.",
      marketValue: 1.22,
      portfolioPercent: 34.21,
    },
    {
      symbol: "HOME",
      positionName: "Home Inc.",
      marketValue: 887.00,
      portfolioPercent: 24.01,
    },
    {
      symbol: "DELL",
      positionName: "Dell Inc.",
      marketValue: 123,
      portfolioPercent: 4.21,
    },
  ];
const accountListContent = [
  {
    accountName: "John Retirement",
    accountNumber: "****0022",
    tableData,
  },
  {
    accountName: "Axen Retirement",
    accountNumber: "****0902",
    tableData:tableData1,
  },
];

export interface AssetDataProps {
  accountName: string;
  accountNumber: string;
  data: TableDataProps[];
}

export interface TableDataProps {
  symbol: string;
  positionName?: string;
  marketValue?: string;
  portfolioPercent: number;
}
const AccountData = [
  {
    holdingsTitle: "US Stock",
    accordionArray: [
      {
        holdingsSubtitle: "US Large Cap",
        accountListHeaders: assetListHeaders,
        accountListContent,
      },
      {
        holdingsSubtitle: "US Mid Cap",
        accountListHeaders: assetListHeaders,
        accountListContent,
      },
    ],
  },
  {
    holdingsTitle: "International Stock",
    accordionArray: [
      {
        holdingsSubtitle: "International Large Cap",
        accountListHeaders:assetListHeaders,
        accountListContent,
      },
      {
        holdingsSubtitle: "International Mid Cap",
        accountListHeaders:assetListHeaders,
        accountListContent,
      },
    ],
  },
];

export const AssetHoldingsData = {
  cardTitle: "Holdings",
  holdingsArray: AccountData,
};
