import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import "./loading.css"


const StyledDialog = styled(Dialog)(() => {
    return {
      "& .MuiPaper-root": {
        alignItems: "center",
        justifyContent: "center"
      },
      
    };
  });

export const LoadingScreen = () => {
    return (
        <StyledDialog fullScreen open = {true} BackdropProps={{style: {backgroundColor: 'white'}}}>
            <Box sx = {{ width: "45px"}}>
            <img className="loader" data-testid = "loader" style = {{}} src="/assets/loadingLogo.svg" alt="Loading Outcome"></img>
            <div className="indicator-wrap">
                <div className="linear-progress-bar"></div>
            </div>
            </Box>
        </StyledDialog>
    );
};

export default LoadingScreen;