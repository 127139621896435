import { call, put } from "redux-saga/effects";
import { assetDetailHoldingsFormatter } from "./AssetDetailHoldingHelpers";
import { accountService, errMessage } from "../../../general-config";
import { getCookies, getClientId } from "../../../ui/utils/helper";
import { fetchApi } from "../saga-index";
import { getAssetAllocationDetailSuccess, getAssetAllocationDetailError } from "./AssetAllocationDetail.actions";

export const detailCallUrl = (clientId: string) =>
  fetchApi(`${accountService}/clients/${clientId}/allocations`);

export function* getAssetAllocationDetailSaga(): any {
  try {
    const token = getCookies("access_token");
    if (token) {
      let clientId : string = getClientId(token); 
      if (clientId) {
        const response = yield call(detailCallUrl, clientId); //clientid: 8763221
        if (response?.data) {
          const accountList = yield call(
            assetDetailHoldingsFormatter,
            response?.data?.holdings
          );
          yield put(
            getAssetAllocationDetailSuccess({
              holdings: accountList,
              allocations: response?.data?.allocations,
            })
          );
        } else {
          yield put(getAssetAllocationDetailError(errMessage));
        }
      }
    }
  } catch (e: any) {
    yield put(
      getAssetAllocationDetailError(e?.response?.data?.message || errMessage)
    );
  }
}
