import {
  NetWorthHoldingsData,
} from "./NetWorthHolding";
import { tnbData } from "./TopNavigationBar";

const mockdata = [
  {
    name: "Jan",
    previousYear: 40000,
    currentYear: 35000,
  },
  {
    name: "Feb",
    previousYear: 30000,
    currentYear: 35000,
  },
  {
    name: "Mar",
    previousYear: 40000,
    currentYear: 37000,
  },
  {
    name: "Apr",
    previousYear: 31000,
    currentYear: 30000,
  },
  {
    name: "May",
    previousYear: 40000,
    currentYear: 38000,
  },
];

export const newChartData = [
  {
    name: "Assets",
    uv: 96789, // totalInvestmentAssets
    pv: 76789, //totalOtherAssets
    pvFill: "#00965E",
    uvFill: "#A9DCC9", 
  },
  {
    name: "Liabilities", //totalLiabilities will be minus
    uv: 0,
    pv: -86789,
    pvFill: "#BE1F28",
    uvFill: "#FFF",
  },
  {
    name: "Net worth",
    uv: 0,
    pv: 86789, //totalNetworth
    pvFill: "#0075EE",
    uvFill: "#FFF",
  },
];

const legendData = [
  {
    svgColor: "#55B993",
    name: "Investment Assets",
    amount: "$96,789",
  },
  {
    svgColor: "#00965E",
    name: "Other Assets",
    amount: "$76,789",
  },
  {
    name: "Total  Assets",
    amount: "$173,578",
  },
  { svgColor: "#BE1F28", name: "Liabilities", amount: "$86,789" },
  {
    svgColor: "#0075EE",
    name: "Net Worth",
    amount: "$86,789",
  },
];

export const NetWorthMock = {
  data: {
    title: "Net Worth",
    chartData: newChartData,
    legendData,
    btnText: "See More",
  },
  events: {
    onDragHandleIconClick: () => {},
    onSeeMoreHandler: () => {},
  },
};
export const networthsample={
  totalInvestmentAssets: 676767,
  totalOtherAssets:1000,
  totalAssets:677767,
  totalLiabilities: -56454,
  totalNetworth: 621313,
  totalMarginBalance: 0
}
export const NetworthLegends = [
  {
    key: "#in-asset",
    svgColor: "#00965E",
    name: "Investment Assets",
    amount: "$96,789",
  },
  {
    key: "#oth-asset",
    svgColor: "#55B993",
    name: "Other Assets",
    amount: "$76,789",
  },
  {
    key: "#tot-asset",
    name: "Total  Assets",
    amount: "$173,578",
    svgColor: "",
  },
  {
    key: "#liability",
    svgColor: "#BE1F28",
    name: "Liabilities",
    amount: "$86,789",
  },
];

export const PrintPDFMock = {
  logo: tnbData?.logo?.colorLogoUrl,
  title: "Net Worth",
  address: "3165 E. Millrock Drive, Suite 100 Holladay, Utah 84121",
  date:'Jan 30, 2023 10:00 am',
  phoneNumber: "(385) 401-5200",
  pdfHoldingsArray: NetWorthHoldingsData.pdfHoldingsArray,
  legends : NetworthLegends,
  newChartData : newChartData,
}
