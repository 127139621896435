import { call, put } from "redux-saga/effects";
import { errMessage } from "../../../general-config";
import { getCookies } from "../../../ui/utils/helper";
import { fetchApi } from "../saga-index";
import { getMarketPerformanceSuccess, getMarketPerformanceFailure } from "./MarketPerformance.actions";

export const apiCall = () =>
  fetchApi(`market-service/v1/markets/performance`)

export function* getMarketPerformanceSaga(): any {
  try {
    const token = getCookies('access_token');
    if(token) {
      const response = yield call(apiCall);
      if(response?.data) {
        yield put(getMarketPerformanceSuccess(response?.data));
      }
      else {
        yield put(getMarketPerformanceFailure(errMessage));
      }
    }
    
  } catch (e : any) {
    yield put(getMarketPerformanceFailure(e?.response?.data?.message || errMessage));
  }
}