export enum NetWorthDetailActionType{
    GET_NETWORTH_DETAIL='GET_NETWORTH_DETAIL',
    GET_NETWORTH_DETAIL_SUCCESS='GET_NETWORTH_DETAIL_SUCCESS',
    GET_NETWORTH_DETAIL_ERROR='GET_NETWORTH_DETAIL_ERROR',

}

interface GetNetWorthDetail{
    type:NetWorthDetailActionType.GET_NETWORTH_DETAIL,
    payload:any,  
}

interface GetNetWorthDetailSuccess{
    type:NetWorthDetailActionType.GET_NETWORTH_DETAIL_SUCCESS,
    payload:any,  
}
interface GetNetWorthDetailError{
    type:NetWorthDetailActionType.GET_NETWORTH_DETAIL_ERROR,
    payload:any,  
}
export type NetWorthDetailAction=
| GetNetWorthDetail
| GetNetWorthDetailSuccess
| GetNetWorthDetailError