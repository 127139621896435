import React, { FC } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {TopNavigationBar} from "../../organisms/TopNavigationBar";
import {TopNavigationBarMobile} from "../../organisms/TopNavigationBarMobile";
import {ContactModal} from "../../organisms/ContactModal";
import {TopNavigationBarSmallDesktop} from "../../organisms/TopNavigationBarSmallDesktop";
import { contactModalMock } from "../../mock/ContactModal";
import { TopNavigationBarWrapperProps } from "./TopNavigationBarWrapper.type";
import { useLocation } from "react-router-dom";

export const TopNavigationBarWrapper: FC<TopNavigationBarWrapperProps> = ({
  data,
  events,
  isTransition = false,
  isFirmUser = false,
}) => {
  const { navLinks, contactUsText, logo, logOutText, externalLinkData } = data;
  const { handleContactUs, handleSwitchToggle, handleLogOut } = events;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(900));
  const isSmallDesktop = useMediaQuery(theme.breakpoints.between(900, 1300));
  const [contactModalOpen, setContactModalOpen] = React.useState(false);
  const location = useLocation();
  const isFirmUserLanding = isFirmUser && location?.pathname === "/";
  return (
    <>
      {isMobile && (
        <TopNavigationBarMobile
          navLinks={navLinks}
          contactUsText={contactUsText}
          logo={logo}
          logOutText={logOutText}
          externalLinkData={externalLinkData}
          isTransition={isTransition}
          isFirmUser={isFirmUser}
          events={{
            handleContactUs: handleContactUs,
            handleSwitchToggle: handleSwitchToggle,
            handleLogOut: handleLogOut,
          }}
        />
      )}
      {!isMobile && !isSmallDesktop && (
        <TopNavigationBar
          navLinks={navLinks}
          contactUsText={contactUsText}
          logOutText={logOutText}
          externalLinkData={externalLinkData}
          logo={logo}
          isTransition={isTransition}
          isFirmUser={isFirmUser}
          events={{
            handleContactUs: handleContactUs,
            handleSwitchToggle: handleSwitchToggle,
            handleLogOut: handleLogOut,
          }}
        />
      )}
      {isSmallDesktop && !isFirmUserLanding && (
        <TopNavigationBarSmallDesktop
          navLinks={navLinks}
          contactUsText={contactUsText}
          logo={logo}
          logOutText={logOutText}
          externalLinkData={externalLinkData}
          isTransition={isTransition}
          isFirmUser={isFirmUser}
          events={{
            handleContactUs: handleContactUs,
            handleSwitchToggle: handleSwitchToggle,
            handleLogOut: handleLogOut,
          }}
        />
      )}
      {isSmallDesktop && isFirmUserLanding && (
        <TopNavigationBar
          navLinks={navLinks}
          contactUsText={contactUsText}
          logOutText={logOutText}
          externalLinkData={externalLinkData}
          logo={logo}
          isTransition={isTransition}
          isFirmUser={isFirmUser}
          events={{
            handleContactUs: handleContactUs,
            handleSwitchToggle: handleSwitchToggle,
            handleLogOut: handleLogOut,
          }}
        />
      )}
      <ContactModal
        data={{ ...contactModalMock.data, isOpen: contactModalOpen }}
        events={{ handleClose: () => setContactModalOpen(false) }}
      />
    </>
  );
};
export default TopNavigationBarWrapper;
