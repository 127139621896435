const MoneyGuide=require('../../images/MoneyGuide(4x).webp');
const LPL=require('../../images/LPL(4x).webp');
const Fidelity=require('../../images/F.webp');
const Charles=require('../../images/Charles(4x).webp');
const Ameritrade=require('../../images/Ameritrade(4x).webp');
const outcomeLogo=require('../../images/outcomeLogo.webp');
const outcomeLogoColor=require('../../images/outcomeLogoColor.webp');

export const tnbData = {
  logo: {
    whiteLogoUrl: outcomeLogo,
    colorLogoUrl: outcomeLogoColor,
    link: "/",
  },
  navLinks: [
    { label: "Dashboard", link: "/",target:"_self" },
    { label: "Net Worth", link: "/networth",target:"_self" },
    { label: "Outcome Asset Allocation", link: "/asset",target:"_self" },
    {
      label: "Custodial Login",
      link: "/",
      externalLink: true,
      target:"_self"
    },
  ],
  externalLinkData: {
    label: "Custodial Login",
    navLinks: [
      // { label: "Wells Fargo", image : WellsFargo , link: "https://connect.secure.wellsfargo.com/auth/login/present?origin=cob&LOB=CONS",target:"_blank" },
      { label: "LPL Financial", image : LPL , link: "https://myaccountviewonline.com/login/",target:"_blank" },
      { label: "Envestnet Money Guide", image : MoneyGuide , link: "https://www.moneyguidepro.com/ifa/SignOn.aspx" ,target:"_blank"},
      { label: "Fidelity", image : Fidelity , link: "https://www.fidelity.com/",target:"_blank" },
      { label: "Charles Schwab", image : Charles , link: "https://client.schwab.com/Login/SignOn/CustomerCenterLogin.aspx",target:"_blank" },
      { label: "TD Ameritrade", image : Ameritrade , link: "https://www.tdameritrade.com/",target:"_blank" },
    ],
  },
  contactUsText: "Contact Us",
  logOutText: "Log Out",
  handleContactUs: () => {
    console.log("Click Contact Us");
  },
  handleSwitchToggle: () => {
    console.log("switch toggled");
  },
  handleLogOut: () => {
    console.log("logout");
  },
};

export const tnbDataAsClient = [
    { label: "Dashboard", link: "/clientDashboard",target:"_self" },
    { label: "Net Worth", link: "/clientNetworth",target:"_self" },
    { label: "Outcome Asset Allocation", link: "/clientAsset",target:"_self" },
    {
      label: "Custodial Login",
      link: "/",
      externalLink: true,
      target:"_self"
    },
  ]