import { getInitialLayout } from "../../../ui/utils/helper";
import { GetUserPreferenceAction, UserPreferenceActionType } from "./UserPreferences.action-types";


const INITIAL_STATE = {
  themeData: [
    {
      type: "themeMode",
      value: localStorage.getItem("themeMode")
        ? localStorage.getItem("themeMode")
        : "light",
    },
    { type: "layout",
     value: localStorage?.getItem('layout') ? JSON.parse(localStorage?.getItem('layout') ??
      JSON.stringify(getInitialLayout())) : getInitialLayout()},
  ],
  isLoading: false,
  error: "",
  toast:false,
};

export const getThemes = (data: any) => {
  if (data.length === 0) {
    return "light";
  } else {
    const defaultMode = data.find((el: any) => el.type === "themeMode").value;
    return defaultMode;
  }
};

export const getData = (payload: any, themeDataState: any) => {
  let data = themeDataState;
  let index = themeDataState?.findIndex((th: any) => th?.type === payload?.type);
  if (index !== -1) {
    data[index].value = payload?.value;
  }
  return data;
};

const getSuccessData = (state:any, payload:any) => {
  return {
    ...state,
    isLoading: false,
    themeData: getData(payload, state.themeData),
  }; 
}

const getActionPayload = (payload:any) => {
  if(payload?.find((f:any) => f?.type === 'themeMode')){
    return payload
  } else {
    return [...payload, {type: 'themeMode', value: 'light'}]
  }
}

const UserPreferenceReducer = (
  state = INITIAL_STATE,
  action: GetUserPreferenceAction
) => {
  switch (action.type) {
    case UserPreferenceActionType.GET_USER_PREFERENCE:
      return { ...state, isLoading: true };
    case UserPreferenceActionType.GET_USER_PREFERENCE_SUCCESS:
      return { ...state, isLoading: false, themeData: action?.payload?.length === 0 ? INITIAL_STATE.themeData : getActionPayload(action.payload) };
    case UserPreferenceActionType.GET_USER_PREFERENCE_ERROR:
      return { ...state, isLoading: false, error: action.payload,toast:true };
    case UserPreferenceActionType.PUT_USER_PREFERENCE:
      return { ...state, isLoading: true };
    case UserPreferenceActionType.PUT_USER_PREFERENCE_SUCCESS:
      return getSuccessData(state,action.payload);
    case UserPreferenceActionType.PUT_USER_PREFERENCE_ERROR:
      return { ...state, isLoading: false, error: action.payload, toast:true };
    case UserPreferenceActionType.SHOW_USER_PREFERENCE_TOAST:
      return {...state,isLoading: false,toast:false}
    default:
      return { ...state };
  }
};

export default UserPreferenceReducer;
