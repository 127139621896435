import { AdminActionType } from "./Admin.action-types";

export const getClients = () => ({
  type: AdminActionType.GET_CLIENTS,
});

export const getClientsSuccess = (payload : any) => ({
  type: AdminActionType.GET_CLIENTS_SUCCESS,
  payload
});

export const getClientsError = (error : any) => ({
  type: AdminActionType.GET_CLIENTS_ERROR,
  payload: error
})

export const getPortfolioId = () => ({
  type: AdminActionType.GET_PORTFOLIO_ID,
})