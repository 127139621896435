import React, { useState, useEffect, FC } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Link from "@mui/material/Link";
import Drawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  AccountBoxStyle,
  TopNavigationBarMobileProps,
  TransitionTypeMobileProps,
} from "./TopNavigationBarMobile.type";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminNavBar } from "../AdminNavBar";
import {
  StyledCloseIcon,
  StyledContainer,
  StyledLink,
  StyledMenuBox,
  StyledTopIconBox,
} from "../../styles/common.styled";
import { getLogo, isNoHeader } from "../../../utils/helper";
import ThemeSwitch from "../../../utils/components/ThemeSwitch.component";
import { useSelector } from "react-redux";
import ExternalDrawer from "./ExternalDrawer.component";
import { NavigationEntity } from "../TopNavigationBar/TopNavigationBar.type";

const StyledToolbarMobile = styled(Toolbar)<TransitionTypeMobileProps>(
  ({ theme, transition }) => {
    return {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "0px",
      // gap: "24px",
      flex: "none",
      order: 0,
      flexGrow: 0,
      justifyContent: transition === "true" ? "center" : "space-between",
    };
  }
);


const StyledBoxMobile = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

const StyledButtonBox = styled(Box)<AccountBoxStyle>(({ theme, marginTop }) => {
  return {
    bottom: "-40%",
    boxSizing: "border-box",
    display: "grid",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "0px",
    paddingLeft: "24px",
    gap: "32px",
    marginTop: marginTop,
    paddingBottom: "39px",
  };
});

const StyledExternalLink = styled(StyledLink)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px",
    width: "100%",
  };
});


const StyledContactUs = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px"
  };
});
const StyledButton1 = styled(Button)(({ theme }) => {
  return {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "32px",
    color: theme.palette.text.primary,
    textTransform: "none",
    padding: "6px 4px",
  };
});

const StyledKeyboardArrowRightIcon = styled(KeyboardArrowRightIcon)(
  ({ theme }) => {
    return {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    };
  }
);

const StyledContactIcon = styled(ContactPageOutlinedIcon)(({ theme }) => {
  return {
    "&.MuiSvgIcon-root": {
      fontSize: "23px",
    },
  };
});
const StyledLogoutIcon = styled(LogoutIcon)(({ theme }) => {
  return {
    "&.MuiSvgIcon-root": {
      fontSize: "23px",
    },
  };
});

export const TopNavigationBarMobile: FC<TopNavigationBarMobileProps> = ({
  logo,
  navLinks,
  contactUsText,
  logOutText,
  externalLinkData,
  events,
  isTransition = false,
  isFirmUser = false,
}) => {
  const { handleContactUs, handleSwitchToggle, handleLogOut } = events;
  const [isSidebar, setIsSidebar] = useState(false);
  const [isExternalDrawer, setIsExternalDrawer] = useState(false);
  const [stickyClass, setStickyClass] = useState("relative");
  const location = useLocation();
  const navigate = useNavigate();
  const isFirmUserLanding =
    isFirmUser && (location?.pathname === "/" || isNoHeader());
  let tempName = localStorage.getItem("clientName");
  const clientName = isFirmUser && tempName ? tempName : "";
  const theme=useTheme();

  const themeData= useSelector(
    (state:any) =>  state?.userPreferenceReducer?.themeData);
    const getAppBarBackground = () => {
    if (isFirmUserLanding && stickyClass !== "fixed") {
      return "transparent";
    } else if (isFirmUserLanding && stickyClass === "fixed") {
      return "#EEEFF1";
    } else if (stickyClass === "fixed") {
      return theme.palette.common.stickyNavBar;
    } else {
      return "transparent";
    }
  };
  const StyledMobileAppBar = styled(AppBar)(({ theme }) => {
    return {
      background: getAppBarBackground(),
      boxShadow:
        stickyClass === "fixed"
          ? "0px 6px 10px 4px rgba(0, 0, 0, 0.09), 0px 2px 3px rgba(0, 0, 0, 0.1)"
          : "none",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "8px 16px",
      gap: "8px",
    };
  });
  const getIconColor=()=>{
    if (isFirmUserLanding && stickyClass != "fixed") {
      return '#FFFFFF';
    } else if (isFirmUserLanding && stickyClass === "fixed") {
      return '#181A19';
    } else if (
      !isFirmUserLanding &&
      stickyClass === "fixed"
    ) {
      return theme.palette.text.primary;
    } else {
      return '#FFFFFF';
    }
  }
  const StyledIconButton = styled(IconButton)(({ theme }) => {
    return {
      color: getIconColor(),
    };
  });

  const StyledDrawer = styled(Drawer)(({ theme }) => {
    return {
      "& .MuiDrawer-paper": {
        width: "100%",
        backgroundColor:isFirmUserLanding?'rgba(255, 255, 255, 1)':theme.palette.common.drawerBackground,
      },
    };
  });

  const onIconClick = () => {
    setIsSidebar(!isSidebar);
  };

  const stickyNavbar = () => {
    if (window !== undefined) {
      const windowHeight = document.body.scrollTop;
      windowHeight >= 50 ? setStickyClass("fixed") : setStickyClass("relative");
    }
  };

  useEffect(() => {
    document.body.addEventListener("scroll", stickyNavbar);

    return () => {
      document.body.removeEventListener("scroll", stickyNavbar);
    };
  }, []);

  const handleExternalLink = () => {
    setIsExternalDrawer(true);
  };

  const onBackArrowClick = () => {
    setIsExternalDrawer(false);
  };
  
  const getLogoutColor=()=>{
    if (isFirmUserLanding && stickyClass !=="fixed") {
      return '#181A19';
    } else if (isFirmUserLanding && stickyClass === "fixed") {
      return '#181A19';
    } else
    {
      return theme.palette.text.primary;
    } 
  }
    const StyledLogout = styled(StyledButton1)(({ theme }) => {
      return {
        color: getLogoutColor(),
      };
    });

    const CustomisedCloseIcon=styled(StyledCloseIcon)(({theme})=>{
      return {
        "&.MuiButtonBase-root" : {
          color:isFirmUserLanding?'#181A19':theme.palette.text.primary,
        },
      }
      
    });
  return (
    <>
      {isFirmUser && !isFirmUserLanding && (
        <AdminNavBar
          clientName={clientName}
          onCtaBackClick={() => navigate("/")}
        />
      )}
      <StyledMobileAppBar
        position="static"
        sx={{
          position: stickyClass === "fixed" ? "sticky" : "static",
          top: 0,
          zIndex: 100,
        }}
      >
        <StyledContainer maxWidth="xl">
          <StyledToolbarMobile
            disableGutters
            transition={isTransition.toString()}
          >
            <StyledBoxMobile>
              {!isTransition && (
                <StyledIconButton
                  size="large"
                  edge="start"
                  aria-label="menu"
                  onClick={onIconClick}
                  data-testid="menu-icon"
                >
                  <MenuOutlinedIcon />
                </StyledIconButton>
              )}
              <Box>
                <Link aria-label="tnb-mobile" href={logo.link} underline="none">
                  <img
                    src={getLogo(isFirmUserLanding,stickyClass, logo, themeData)}
                    width="128px"
                    height="49px"
                    alt={logo?.link}
                  />
                  {/* 40px previous height */}
                </Link>
              </Box>
            </StyledBoxMobile>
          </StyledToolbarMobile>
        </StyledContainer>
      </StyledMobileAppBar>

      <StyledDrawer
        anchor="left"
        open={isSidebar}
        onClose={() => setIsSidebar(false)}
        data-testid="mobile-drawer"
      >
        <Box>
          <StyledTopIconBox>
            <CustomisedCloseIcon
              size="large"
              edge="start"
              aria-label="close"
              onClick={onIconClick}
              data-testid="close-icon"
            >
              <CloseIcon />
            </CustomisedCloseIcon>
            {!isFirmUserLanding && (
              <ThemeSwitch   handleToggle={handleSwitchToggle} />
            )}
          </StyledTopIconBox>
          {!isFirmUserLanding && (
            <StyledMenuBox>
              {navLinks.map((page:NavigationEntity, index: number) => (
                <div key={page.label + `${index}`} style={{ width: "100%" }}>
                  {page?.externalLink ? (
                    <StyledExternalLink
                      underline="none"
                      href="#"
                      onClick={handleExternalLink}
                      data-testid="external-link"
                      target={page.target}
                    >
                      {page.label}
                      <StyledKeyboardArrowRightIcon />
                    </StyledExternalLink>
                  ) : (
                    <StyledLink
                      underline="none"
                      href={page.link}
                      target={page.target}
                    >
                      {page.label}
                    </StyledLink>
                  )}
                </div>
              ))}
            </StyledMenuBox>
          )}
        </Box>
        <StyledButtonBox marginTop={isFirmUserLanding ? "24px" : "auto"}>
          {!isFirmUserLanding && (
            <StyledContactUs>
              <StyledButton1
                startIcon={<StyledContactIcon />}
                onClick={handleContactUs}
                data-testid="contact-us"
              >
                {contactUsText}
              </StyledButton1>
            </StyledContactUs>
          )}
          <StyledContactUs>
            <StyledLogout
              startIcon={<StyledLogoutIcon />}
              onClick={handleLogOut}
              data-testid="logout"
            >
              {logOutText}
            </StyledLogout>
          </StyledContactUs>
        </StyledButtonBox>
      </StyledDrawer>

<ExternalDrawer  isExternalDrawer={isExternalDrawer} onBackArrowClick={onBackArrowClick}  externalLinkData={externalLinkData}  drawerCloseEvent={()=> setIsExternalDrawer(false)}/>
    </>
  );
};
export default TopNavigationBarMobile;
