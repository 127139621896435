export enum AdminActionType {
    GET_CLIENTS = 'GET_CLIENTS',
    GET_CLIENTS_ERROR = 'GET_CLIENTS_ERROR',
    GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS',
    GET_PORTFOLIO_ID = 'GET_PORTFOLIO_ID'
}
interface GetClients {
    type: AdminActionType.GET_CLIENTS;
    payload?: any;
}
interface GetClientsSuccess {
    type: AdminActionType.GET_CLIENTS_SUCCESS;
    payload: any;
}
  
interface GetClientsError {
    type: AdminActionType.GET_CLIENTS_ERROR;
    payload: any;
}

interface GetPortfolioId {
    type : AdminActionType.GET_PORTFOLIO_ID;
}
    
export type GetAdminDetailsActionType =
    | GetClients
    | GetClientsSuccess
    | GetClientsError | GetPortfolioId;



