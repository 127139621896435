import { call, put } from "redux-saga/effects";
import { fetchApi, mgpPortfolio } from "../saga-index";
import { errMessage, mgpService } from "../../../general-config";
import { getCookies } from "../../../ui/utils/helper";
import { getMoneyGuideImageFailure, getMoneyGuideImageSuccess } from "./MoneyGuide.actions";


export const moneyGuideImageCall = (portfolioId : string) =>
  fetchApi(`${mgpService}/monte-carlo-meter?portfolioId=${portfolioId}`)

export function* getMoneyGuideSaga(): any {
  try {
    const token = getCookies('access_token');
    if(token) {
      const portfolioId = yield call(mgpPortfolio);
      if(portfolioId) {
        const response = yield call(moneyGuideImageCall,portfolioId);
        if(response?.data) {
          yield put(getMoneyGuideImageSuccess(response?.data));
        }
        else {
          yield put(getMoneyGuideImageFailure({errorMessage:errMessage,responseCode:500}));
        }
      }
      else {
        yield put(getMoneyGuideImageFailure({errorMessage:errMessage,responseCode:500}));
      }
    }
    else {
      yield put(getMoneyGuideImageFailure({errorMessage:errMessage,responseCode:500}));
    }
  } catch (e : any) {
    yield put(getMoneyGuideImageFailure({errorMessage:e?.response?.data?.message || errMessage,responseCode:e?.response?.data?.code||500}));
  }
}