import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import { ContactModalProps } from "./ContactModal.type";
import { InfoContainer } from "./ContactModal.component";
import ArrowBack from "@mui/icons-material/ArrowBack";

const Puller = styled(Box)(({ theme }) => ({
  width: 48,
  height: 4,
  backgroundColor: "#ADB5B2",
  borderRadius: 2,
  position: "absolute",
  bottom: 16,
  left: "calc(50% - 15px)",
}));

const MainWrapper = styled(Box)(({ theme }) => ({
  padding: "18px 32px 18px 32px",
}));

const HeadingBox = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    marginBottom: "34px",
  };
});

const StyledTypography = styled(Typography)(({ theme }) => {
  return {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.common.switchThumbBackground,
    paddingLeft: "22px"
  };
});

const paperProps = {
    background: "#FFFFFF",
} 
const StyledIconButton=styled(IconButton)(({theme})=>{
  return {
    color: theme.palette.common.switchThumbBackground,
    padding: "0"
  }
})
const StyledDrawer=styled(Drawer)(({theme})=>{
  return {
    "& .MuiDrawer-paper": {
       backgroundColor: theme.palette.common.contactDrawer,
       height: "100%",
       width: "100%"
    },
  }
})
export const ContactModalMobile = ({ data, events }: ContactModalProps) => {
  return (
    <StyledDrawer
      anchor={"left"}
      open={data.isOpen}
      PaperProps={{
        sx: paperProps,
      }}
    >
      <MainWrapper>
        <HeadingBox>
          <StyledIconButton
              aria-label="close"
              onClick={events.handleClose}
              data-testid="close-icon"
            >
              <ArrowBack />
            </StyledIconButton>
          <StyledTypography data-testid="modal-title">
            {data.title}
          </StyledTypography>
          
        </HeadingBox>
        <img
          src={data.mapSource}
          alt={data.mapSource}
          loading="lazy"
          data-testid="contact-map"
          style={{
            width: "100%",
            objectFit: "contain",
            margin: "32px 0px 32px",
            pointerEvents: 'none'
          }}
        />
        <InfoContainer {...data}/>
      </MainWrapper>
    </StyledDrawer>
  );
};
