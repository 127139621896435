import { all, AllEffect, call, ForkEffect, takeEvery } from 'redux-saga/effects';
import { getRequest, putRequest } from '../../api/api-config';
import { checkOrOperatorValidity, getClientId, getCookies } from '../../ui/utils/helper';
import { MoneyGuideActionType } from './MoneyGuide/MoneyGuide.action-types';
import { getMoneyGuideSaga } from './MoneyGuide/MoneyGuideSaga';
import { MarketPerformanceActionType } from './MarketPerformance/MarketPerformance.action-types';
import { getMarketSearchlistSaga } from './MarketWatch/MarketSearchSaga';
import { AuthActionType } from './Auth/Auth.action-types';
import { getAccountDetailHoldingSaga } from './AccountDetailHolding/AccountDetailHoldingSaga';
import { getNetworthGraphSaga } from './Networth/NetWorthSaga';
import { getNetWorthPdfSaga } from './NetWorthPdf/NetWorthPdfSaga';
import { AccountDetailHoldingActionType } from './AccountDetailHolding/AccountDetailHolding.action-types';
import { AccountListActionType } from './AccountList/AccountList.action-types';
import { getAccountListCallSaga } from './AccountList/AccountListSaga';
import { AdminActionType } from './Admin/Admin.action-types';
import { getClientListSaga, getClientPortfolioId, portfolioIdCall } from './Admin/AdminSaga';
import { AssetAllocationDetailActionType } from './AssetAllocationDetail/AssetAllocationDetail.action-types';
import { getAssetAllocationDetailSaga } from './AssetAllocationDetail/AssetAllocationDetailSaga';
import { getRefreshToken } from './Auth/AuthSaga';
import { getMarketPerformanceSaga } from './MarketPerformance/MarketPerformanceSaga';
import { getMarketStarUnstarSaga } from './MarketWatch/MarketStarUnstarSaga';
import { MarketWatchActionType } from './MarketWatch/MarketWatch.action-types';
import { getMarketWatchlistSaga } from './MarketWatch/MarketWatchSaga';
import { NetWorthActionType } from './Networth/NetWorth.action-types';
import { NetWorthDetailActionType } from './NetWorthDetail/NetWorthDetail.action-types';
import { getNetworthDetailSaga } from './NetWorthDetail/NetWorthDetailSaga';
import { NetWorthPdfActionType } from './NetWorthPdf/NetWorthPdf.action-types';
import { OutcomeGraphActionType } from './OutcomeGraph/OutcomeGraph.action-types';
import { getOutcomeGraphSaga } from './OutcomeGraph/OutcomeGraphSaga';
import { UserPreferenceActionType } from './UserPreference/UserPreferences.action-types';
import { getUserPreferenceCallSaga, putUserPreferenceCallSaga } from './UserPreference/UserPreferenceSaga';
import { MoneyGuideSSOActionType } from './MoneyGuideSSO/MoneyGuideSSO.action-types';
import { getMoneyGuideSSOSaga } from './MoneyGuideSSO/MoneyGuideSSO';
import { ChangePortfolioActionType } from './ChangePortfolio/ChangePortfolio.action-types';
import { getPortfolioSaga, putPortfolioSaga } from './ChangePortfolio/ChangePortfolio';

export function* fetchApi( url : string) : any {
    let expiration = parseInt(checkOrOperatorValidity(localStorage.getItem('expiration'), "0" ))
    if (expiration && new Date(expiration * 1000) <= new Date()) {
        const isRefreshed = yield call(getRefreshToken)
        if(isRefreshed) {
            return yield call(getRequest, url)
        }
        return {}
    } else {
        return yield call(getRequest, url)
    }
}

export function* mgpPortfolio() : any {
  let portfolioId : string = localStorage.getItem("portfolioId") ?? "";
  if(!portfolioId) {
    const token = getCookies('access_token')
    let clientId : string = getClientId(token);
    if(token) {
      let result : any = yield call(portfolioIdCall, clientId);
      portfolioId = result?.data?.id;
    }
  }
  return portfolioId;
}

export function* fetchPutApi( url : string, payload: any) : any {
  let expiration = parseInt(checkOrOperatorValidity(localStorage.getItem('expiration'), "0" ))
  if (expiration && new Date(expiration * 1000) <= new Date()) {
      const isRefreshed = yield call(getRefreshToken)
      if(isRefreshed) {
          return yield call(putRequest, url, payload)
      }
      return {}
  } else {
      return yield call(putRequest, url, payload)
  }
}

export function* watchPortfolioId(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(AdminActionType.GET_PORTFOLIO_ID, getClientPortfolioId);
}

export function* watchMoneyGuide(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(MoneyGuideActionType.GET_MONEYGUIDE_IMAGE, getMoneyGuideSaga);
}

export function* watchMoneyGuideSSO(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(MoneyGuideSSOActionType.GET_MONEYGUIDE_SSO, getMoneyGuideSSOSaga);
}

export function* watchMarketPerformance(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(MarketPerformanceActionType.GET_MARKET_PERFORMANCE, getMarketPerformanceSaga);
}

export function* watchGetOutcomeGraph(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(OutcomeGraphActionType.GET_OUTCOMEGRAPH, getOutcomeGraphSaga);
}

export function* watchMarketWatchlist(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(MarketWatchActionType.GET_MARKETWATCHLIST, getMarketWatchlistSaga);
}

export function* watchMarketSearchlist(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(MarketWatchActionType.GET_MARKETSEARCHLIST, getMarketSearchlistSaga);
}

export function* watchMarketMarkUnMark(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(MarketWatchActionType.PUT_STAR_UNSTAR, getMarketStarUnstarSaga);
}

export function* watchGetAccountList(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(AccountListActionType.GET_ACCOUNTLIST, getAccountListCallSaga);
}

export function* watchRefreshAuth(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(AuthActionType.REFRESH_AUTH, getRefreshToken);
}

export function* watchGetClientList(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(AdminActionType.GET_CLIENTS, getClientListSaga);
}

export function* watchAccountDetailHolding(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(
    AccountDetailHoldingActionType.GET_ACCOUNTDETAIL_HOLDING,
    getAccountDetailHoldingSaga
  );
}

export function* watchAssetAllocationDetail(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(
    AssetAllocationDetailActionType.GET_ASSETALLOCATION_DETAIL,
    getAssetAllocationDetailSaga
  );
}

export function* watchGetUserPreference(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(UserPreferenceActionType.GET_USER_PREFERENCE, getUserPreferenceCallSaga);
}

export function* watchGetPortfolio(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(ChangePortfolioActionType.GET_PORTFOLIO, getPortfolioSaga);
}

export function* watchPutPortfolio(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(ChangePortfolioActionType.PUT_PORTFOLIO, putPortfolioSaga);
}

export function* watchPutUserPreference(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(UserPreferenceActionType.PUT_USER_PREFERENCE, putUserPreferenceCallSaga);
}


export function* watchNetWorthGraph(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(NetWorthActionType.GET_NETWORTH, getNetworthGraphSaga);
}

export function* watchNetWorthDetail(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(NetWorthDetailActionType.GET_NETWORTH_DETAIL, getNetworthDetailSaga);
}

export function* watchNetWorthPdf(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(NetWorthPdfActionType.GET_NETWORTH_PDF, getNetWorthPdfSaga);
}
export default function* rootSaga(): Generator<
  AllEffect<Generator<ForkEffect<never>, void, unknown>>,
  void,
  unknown
> {
    yield all([
        watchRefreshAuth(),
        watchGetAccountList(),
        watchGetOutcomeGraph(),
        watchAccountDetailHolding(),
        watchAssetAllocationDetail(),
        watchMarketWatchlist(),
        watchMarketSearchlist(),
        watchMarketMarkUnMark(),
        watchGetClientList(),
        watchMarketPerformance(),
        watchMoneyGuide(),
        watchPortfolioId(),
        watchGetUserPreference(),
        watchPutUserPreference(),
        watchNetWorthGraph(),
        watchNetWorthDetail(),
        watchNetWorthPdf(),
        watchMoneyGuideSSO(),
        watchGetPortfolio(),
        watchPutPortfolio()
    ]);
}