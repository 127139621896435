export const apiDomain = process.env.REACT_APP_API_DOMAIN || "https://dev-api.outcomeprivatewealth.com";
export const requestTimeout = 30000;
export const errMessage = "Something went wrong. Please try again.";
export const accountService = "account-service/v1";
export const mgpService='mgp-service/v1';
export const transitionPageTime = process.env.REACT_APP_TRANSITION_TIME;
export const accountListBDUrl = "https://ix.bdreporting.com";
export const allocationBDUrl = "https://ix.bdreporting.com/Portfolio/View/Allocation"; 
export const vaultUrl = "https://ix.bdreporting.com/Vault";
export const portfolioUrl = "https://ix.bdreporting.com/Portfolio";
export const bdAdminUrlPrefix = "https://bd3.bdreporting.com";
export const bdAdminVaultUrl = "https://docs.bdreporting.com/Terms";
export const redirectUrl = process.env.REACT_APP_REDIRECT_URL || "https://dev.outcomeprivatewealth.com";
export const clientId = process.env.REACT_APP_CLIENT_ID || "3372_OutcomeApi";
export const scope = process.env.REACT_APP_SCOPE || "offline_access%20openid";
export const state = process.env.REACT_APP_STATE || "DVoZ62p80BJvqpP1AHZYaT0WUil0KgjklnrgNcieC9M%3D"
export const loginBDUrl = `https://login.bdreporting.com/connect/authorize?response_type=code&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUrl}`
export const idleTime= process.env.REACT_APP_IDLETIME || '300000';
// "http://localhost:8082/auth-code";
//https://dev-api.outcomeprivatewealth.com/auth-service/auth-code
export const isTestingMode = process.env.REACT_APP_TEST_MODE || "true"
export const NoDataMsg = 'No Data Available'
export const NoUserFoundMsg = 'User Not Found';
export const InternalErrorMsg = 'Internal Server Error';
export const UnauthErrMsg = 'Unauthorized';
export const badRequestMsg = 'Bad Request';
export const mgpChartNullMsg = "Snapshot is not available";
export const pdfDisclaimerTitle = "Disclaimer";
export const getUserPrefError = "Unable to fetch User Preference";
export const setUserPrefError = "Unable to store User Preference";
export const pdfDisclaimerPara1 = "Securities offered through LPL Financial. Member FINRA/SIPC. Investment advice offered through Stratos Wealth Partners, a registered investment advisor. Stratos Wealth Partners, Outcome Private Wealth and LPL Financial are separate entities.";
export const pdfDisclaimerPara2 = "The report is provided for informational purposes as a courtesy to you. Content and data provided by various third parties, including Black Diamond, MoneyGuide Pro, Yodlee and ByAll. The information in this report has been prepared from data believed to be reliable, but we can make no representation as to its accuracy and completeness.  The information may also include data provided by custodians directed by you and we can provide no representation as to the accuracy and completeness of that data.  Some of the information in this report may be based upon information by you (the client). We recommend that you review your plan annually unless changes in your personal or financial circumstances require more frequent review. We do not provide tax or legal advice and the information is not intended to be tax or legal advice. Please consult legal or tax professionals for specific information regarding your individual situation. This information is provided as a convenience and is not a substitute for your custodial account statements, nor is it intended for trading purposes. Please consult your account statement, and any statements from the respective financial institution for the accounts provided for information regarding your account balance, positions, and transactions. Price and Market Values are reflective of the previous trading day closing price. Asset allocation does not ensure a profit or protect against loss.";
export const MAXWIDTH = 1600;
export const searchPlaceholder = "Search securities...";
export const searchDesc = "Search securities by Asset Names (ex: Apple Inc) or Symbols (ex: AAPL) and Add Favorites"
export const mgpFormSubmit = process.env.REACT_APP_MGP_DOMAIN ?? "https://dev.moneyguidepro.com/ifa/ssosamlnoref.aspx?IntegrationId=289"
export const noPortfolioMsg = "No Portfolio mapped for this account";
export const noCurrentPortfolioMsg = "No portfolio mapped";