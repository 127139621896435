export enum MoneyGuideSSOActionType {
    GET_MONEYGUIDE_SSO = "GET_MONEYGUIDE_SSO",
    GET_MONEYGUIDE_SSO_SUCCESS = "GET_MONEYGUIDE_SSO_SUCCESS",
    GET_MONEYGUIDE_SSO_ERROR = "GET_MONEYGUIDE_SSO_ERROR",
  }
  
  interface GetMoneyGuideSSO {
    type: MoneyGuideSSOActionType.GET_MONEYGUIDE_SSO;
    payload?: any;
  }
  interface GetMoneyGuideSSOSuccess {
    type: MoneyGuideSSOActionType.GET_MONEYGUIDE_SSO_SUCCESS;
    payload: any;
  }
  
  interface GetMoneyGuideSSOError {
    type: MoneyGuideSSOActionType.GET_MONEYGUIDE_SSO_ERROR;
    payload: any;
  }
  
  export type GetMoneyGuideSSOAction =
    | GetMoneyGuideSSO
    | GetMoneyGuideSSOSuccess
    | GetMoneyGuideSSOError;
  