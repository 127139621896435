import { getCookies } from "../../../ui/utils/helper";
import { AuthAction, AuthActionType } from "./Auth.action-types";


const INIT_STATE = {
  user: {
    user_id: getCookies('user_id'),
    access_token: getCookies('access_token'),
  },
  isLoading: false,
  error: ""
};

let AuthReducer = (state = INIT_STATE, action : AuthAction) => {
  switch (action.type) {
    case AuthActionType.REFRESH_AUTH:
      return { ...state  , isLoading : true };
    case AuthActionType.REFRESH_AUTH_SUCCESS:
      return { ...state, isLoading : false , user : {
        ...state.user,
        access_token: action.payload 
      }};
    case AuthActionType.REFRESH_AUTH_FAILURE:
      return { ...state, isLoading : false , error: action.payload };
    default:
      return { ...state };
  }
}

export default AuthReducer;