import Modal from "@mui/material/Modal";
import {useTheme,styled} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from '@mui/material/Link';
import CloseIcon from "@mui/icons-material/Close";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { ContactModalProps } from "./ContactModal.type";
import { ContactModalMobile } from "./ContactModalMobile";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
  bgcolor: "#FFFFFF",
  boxShadow:
    "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
  borderRadius: "8px",
  outline: 'none',
  // minWidth: '896px'
};

const HeadingBox = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "34px",
  };
});

const InfoWrapperBox = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "32px",
    marginBottom: "32px"
  };
});

const InfoIconButton = styled(IconButton)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "10px",
    width: "36px",
    height: "36px",
    color: "black",
    padding: 0,
  };
});

const InfoTypography = styled(Typography)(({ theme }) => {
  return {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color:theme.palette.common.switchThumbBackground,
  };
});

const StyledTypography = styled(Typography)(({ theme }) => {
  return {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    color: theme.palette.common.switchThumbBackground,
  };
});

const MainContentWrapper = styled(Box)(() => {
  return { display: "flex" };
});

const InfoBox = styled(Box)(({ theme }) => {
  const isMobile = useMediaQuery(theme?.breakpoints.down("md"));
  return { 
    display: "block", 
    minWidth: isMobile ? "100%" : "350px",
    
  };
});

const StyledBox=styled(Box)(({theme})=>{
  return {
   '&.MuiBox-root':{
    background:theme.palette.common.contactModal
   }
  }
})
const StyledLocationOnOutlinedIconIcon=styled(LocationOnOutlinedIcon)(({theme})=>{
  return {
    color:theme.palette.text.primary,
    fontSize: 31.5,
  };
})
const StyledWatchLaterOutlinedIcon=styled(WatchLaterOutlinedIcon)(({theme})=>{
  return {
    color:theme.palette.text.primary,
    fontSize: 31.5,
  };
})
const StyledLocalPhoneOutlinedIcon=styled(LocalPhoneOutlinedIcon)(({theme})=>{
  return {
    color:theme.palette.text.primary,
    fontSize: 31.5,
  };
})
const StyledEmailOutlinedIcon=styled(EmailOutlinedIcon)(({theme})=>{
  return {
    color:theme.palette.text.primary,
    fontSize: 31.5,
  };
})
export const InfoContainer = ({
  address,
  phone,
  mail,
  time,
}: {
  address: string;
  phone: string;
  mail: string;
  time: any;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <InfoBox>
      <InfoWrapperBox>
        <InfoIconButton>
          <StyledLocationOnOutlinedIconIcon />
        </InfoIconButton>
        <InfoTypography>{address}</InfoTypography>
      </InfoWrapperBox>
      <InfoWrapperBox>
        <InfoIconButton>
          <StyledWatchLaterOutlinedIcon />
        </InfoIconButton>
        <InfoTypography>{time}</InfoTypography>
      </InfoWrapperBox>
      <InfoWrapperBox>
        <InfoIconButton>
          <Link href={`tel:${phone}`} color="inherit" underline="none"> 
            <StyledLocalPhoneOutlinedIcon />
          </Link>
        </InfoIconButton>
        <InfoTypography>
          <Link href={`tel:${phone}`} color="inherit" underline="hover"> 
            {phone}
          </Link>
        </InfoTypography>
      </InfoWrapperBox>
      <InfoWrapperBox style={{ marginBottom: isMobile ? 32 : 0 }}>
        <InfoIconButton>
          <Link href={`mailto:${mail}`} color="inherit" underline="none"> 
            <StyledEmailOutlinedIcon />
          </Link>
        </InfoIconButton>
        <InfoTypography>
          <Link href={`mailto:${mail}`} color="inherit" underline="hover"> 
            {mail}
          </Link>
        </InfoTypography>
      </InfoWrapperBox>
    </InfoBox>
  );
};

export const ContactModal = ({ data, events }: ContactModalProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return isMobile ? (
    <ContactModalMobile data={data} events={events} />
  ) : (
    <Modal
      open={data.isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ overflowY: "auto"}}
      disableEscapeKeyDown
    >
      <StyledBox sx={style}>
        <HeadingBox>
          <StyledTypography data-testid="modal-title">
            {data.title}
          </StyledTypography>
          <IconButton
            aria-label="close"
            onClick={events.handleClose}
            data-testid="close-icon"
          >
            <CloseIcon />
          </IconButton>
        </HeadingBox>
        <MainContentWrapper>
          <InfoContainer {...data} />
          <img alt={data.mapSource} style={{pointerEvents: 'none',height: '256px'}} src={data.mapSource} loading="lazy" data-testid="contact-map" />
        </MainContentWrapper>
      </StyledBox>
    </Modal>
  );
};
