import { NetWorthActionType } from "./NetWorth.action-types"


export const getNetWorth=()=>({
    type:NetWorthActionType.GET_NETWORTH
});

export const getNetWorthSuccess =(payload:any)=>({
    type:NetWorthActionType.GET_NETWORTH_SUCCESS,
    payload
})

export const getNetWorthError =(payload:any)=>({
    type:NetWorthActionType.GET_NETWORTH_ERROR,
    payload
})