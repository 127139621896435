import { AccountDetailHoldingActionType } from "./AccountDetailHolding.action-types";

export const getAccountDetailHolding = (payload:any) => ({
  type: AccountDetailHoldingActionType.GET_ACCOUNTDETAIL_HOLDING,
  payload
});

export const getAccountDetailHoldingSuccess = (payload : any) => ({
  type: AccountDetailHoldingActionType.GET_ACCOUNTDETAIL_HOLDING_SUCCESS,
  payload
});

export const getAccountDetailHoldingError = (error : any) => ({
  type: AccountDetailHoldingActionType.GET_ACCOUNTDETAIL_HOLDING_ERROR,
  payload: error
})