export enum ChangePortfolioActionType {
  GET_PORTFOLIO = "GET_PORTFOLIO",
  GET_PORTFOLIO_SUCCESS = "GET_PORTFOLIO_SUCCESS",
  GET_PORTFOLIO_ERROR = "GET_PORTFOLIO_ERROR",
  PUT_PORTFOLIO  = "PUT_PORTFOLIO",
  PUT_PORTFOLIO_SUCCESS = "PUT_PORTFOLIO_SUCCESS",
  PUT_PORTFOLIO_ERROR = "PUT_PORTFOLIO_ERROR",
}
  
interface GetPortfolio {
  type: ChangePortfolioActionType.GET_PORTFOLIO;
  payload?: any;
}
interface GetPortfolioSuccess {
  type: ChangePortfolioActionType.GET_PORTFOLIO_SUCCESS;
  payload?: any;
}

interface GetPortfolioError {
  type: ChangePortfolioActionType.GET_PORTFOLIO_ERROR;
  payload?: any;
}
interface PutPortfolio {
  type: ChangePortfolioActionType.PUT_PORTFOLIO;
  payload?: any;
}
interface PutPortfolioSuccess {
  type: ChangePortfolioActionType.PUT_PORTFOLIO_SUCCESS;
  payload?: any;
}

interface PutPortfolioError {
  type: ChangePortfolioActionType.PUT_PORTFOLIO_ERROR;
  payload?: any;
}

export type ChangePortfolioAction =
 | GetPortfolio
 | GetPortfolioSuccess
 | GetPortfolioError
 | PutPortfolio
 | PutPortfolioSuccess
 | PutPortfolioError;
  
  