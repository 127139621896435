import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { FC } from "react";
import {
  StyledCloseIcon,
  StyledTypography,
} from "../../styles/common.styled";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  NavigationEntity,
  TopNavigationBarProps,
} from "../TopNavigationBar/TopNavigationBar.type";

const StyledDrawer = styled(Drawer)(({ theme }) => {
  return {
    "& .MuiDrawer-paper": {
      width: "350px",
      [theme.breakpoints.down(900)]: {
        width: "100%",
      },
      backgroundColor: theme.palette.common.drawerBackground,
    },
  };
});

const StyledExternalDrawer = styled(StyledDrawer)(({ theme }) => {
  return {
    "& .MuiDrawer-paper": {
      backgroundColor: theme.palette.common.drawerBackground, //"#FFFFFF",
    },
    zIndex:1300
  };
});
const CustomisedTypography = styled(StyledTypography)(({ theme }) => {
  return {
    padding: 0,
  };
});
const StyledLink = styled(Link)(({ theme }) => {
  return {
    color: "#181A19",
    width: "100%",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.15px",
    justifyContent: "center",
    padding: "8px 0px",
    gap: "8px",
    [theme.breakpoints.down(1300)]: {
      fontFamily: "Noto Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
    },
    [theme.breakpoints.down(900)]: {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "32px",
    },
  };
});

const StyledBox = styled(Box)(({ theme }) => {
  return {
    display: "grid",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px 32px",
    gap: "24px",
    marginBottom: "75px",
  };
});
const StyledInnerBox = styled(Box)(({ theme }) => {
  return {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    width: "100%",
    border: "1px solid rgba(0, 150, 94, 0.16)",
    borderRadius: "16px",
  };
});

const StyledOuterBox = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    marginLeft: "24px",
    marginTop: "24px",
    marginBottom: 88,
  };
});

const CustomisedBackIcon = styled(StyledCloseIcon)(({ theme }) => {
  return {
    "&.MuiButtonBase-root": {
      marginLeft: 0,
      marginTop: 0,
      paddingRight: 16,
    },
  };
});
interface ExternalDrawerProps {
  isExternalDrawer: boolean;
  onBackArrowClick: () => void;
  drawerCloseEvent: () => void;
  externalLinkData: TopNavigationBarProps["externalLinkData"];
}
export const ExternalDrawer: FC<ExternalDrawerProps> = ({
  isExternalDrawer,
  onBackArrowClick,
  drawerCloseEvent,
  externalLinkData,
}) => {
  return (
    <StyledExternalDrawer
      anchor="left"
      open={isExternalDrawer}
      onClose={() => drawerCloseEvent}
      data-testid="mobile-external-drawer"
    >
      <Box>
        <StyledOuterBox>
          <CustomisedBackIcon
            size="large"
            edge="start"
            aria-label="arrow"
            onClick={onBackArrowClick}
            data-testid="backArrow-icon"
          >
            <ArrowBackIcon />
          </CustomisedBackIcon>
          <CustomisedTypography>{externalLinkData?.label}</CustomisedTypography>
        </StyledOuterBox>
        <StyledBox sx={{ paddingTop: "0px" }}>
          {externalLinkData?.navLinks?.map((page: NavigationEntity) => (
            <StyledInnerBox key={page.label}>
              <StyledLink
                underline="none"
                href={page.link}
                key={page.label}
                target={page.target}
              >
                <img
                  src={page?.image ?? ""}
                  alt={page?.label}
                  style={{ marginRight: "10px" }}
                  height={93}
                  width={173}
                />
              </StyledLink>
            </StyledInnerBox>
          ))}
        </StyledBox>
      </Box>
    </StyledExternalDrawer>
  );
};
export default ExternalDrawer;
