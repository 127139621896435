import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getCookies } from "../../utils/helper";
import { RouteType } from "./route.types";

const ProtectedRoute = ({ alertOpen = false }: RouteType) => {
 const getFirm = () => {
    if (alertOpen) {
      return true;
    } 
    else if (token && localStorage.getItem("role") === "FirmUser") {
      return true;
    } 
    else 
      return null;
  };
  const token = getCookies("access_token") || "";
  const isFirm = getFirm();
  return isFirm ? <Outlet /> : <Navigate to="/" />;
};
export default ProtectedRoute;
