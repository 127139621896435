export enum AssetAllocationDetailActionType {
    GET_ASSETALLOCATION_DETAIL = 'GET_ASSETALLOCATION_DETAIL',
    GET_ASSETALLOCATION_DETAIL_ERROR = 'GET_ASSETALLOCATION_DETAIL_ERROR',
    GET_ASSETALLOCATION_DETAIL_SUCCESS = 'GET_ASSETALLOCATION_DETAIL_SUCCESS'
  }
  interface GetAssetAllocationData {
    type: AssetAllocationDetailActionType.GET_ASSETALLOCATION_DETAIL;
    payload?: any;
  }
  interface GetAssetAllocationDataSuccess {
    type: AssetAllocationDetailActionType.GET_ASSETALLOCATION_DETAIL_SUCCESS;
    payload: any;
  }
  
  interface GetAssetAllocationDataError {
    type: AssetAllocationDetailActionType.GET_ASSETALLOCATION_DETAIL_ERROR;
    payload: any;
  }
    
  export type GetAssetAllocationDetailAction =
    | GetAssetAllocationData
    | GetAssetAllocationDataSuccess
    | GetAssetAllocationDataError;



