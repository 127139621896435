export enum MarketWatchActionType {
    GET_MARKETWATCHLIST = "GET_MARKETWATCHLIST",
    GET_MARKETWATCHLIST_SUCCESS = "GET_MARKETWATCHLIST_SUCCESS",
    GET_MARKETWATCHLIST_ERROR = "GET_MARKETWATCHLIST_ERROR",
    GET_MARKETSEARCHLIST = "GET_MARKETSEARCHLIST",
    GET_MARKETSEARCHLIST_SUCCESS="GET_MARKETSEARCHLIST_SUCCESS",
    GET_MARKETSEARCHLIST_ERROR = "GET_MARKETSEARCHLIST_ERROR",

    PUT_STAR_UNSTAR = "PUT_STAR_UNSTAR",
    PUT_STAR_UNSTAR_SUCCESS = "PUT_STAR_UNSTAR_SUCCESS",
    PUT_STAR_UNSTAR_ERROR = "PUT_STAR_UNSTAR_ERROR"
  }
  
  //Watchlist--------------------------------------
  interface GetMarketWatchlist {
    type: MarketWatchActionType.GET_MARKETWATCHLIST;
    payload?: any;
  }
  interface GetMarketWatchlistSuccess {
    type: MarketWatchActionType.GET_MARKETWATCHLIST_SUCCESS;
    payload: any;
  }
  
  interface GetMarketWatchlistError {
    type: MarketWatchActionType.GET_MARKETWATCHLIST_ERROR;
    payload: any;
  }

  //Searchlist----------------------------------
  interface GetMarketSearchList {
    type: MarketWatchActionType.GET_MARKETSEARCHLIST;
    payload: string;
  }

  interface GetMarketSearchListSuccess {
    type: MarketWatchActionType.GET_MARKETSEARCHLIST_SUCCESS;
    payload: any;
  }

  interface GetMarketSearchlistError {
    type: MarketWatchActionType.GET_MARKETSEARCHLIST_ERROR;
    payload: any;
  }

  //Star/Unstar-------------------------------------------
  interface PutStarUnstar {
    type: MarketWatchActionType.PUT_STAR_UNSTAR;
    payload: {symbol: string, favorite: boolean};
  }

  interface PutStarUnstarSuccess {
    type: MarketWatchActionType.PUT_STAR_UNSTAR_SUCCESS;
    payload: any;
  }

  interface PutStarUnstarError {
    type: MarketWatchActionType.PUT_STAR_UNSTAR_ERROR;
    payload: any;
  }
  
  export type GetMarketWatchlistAction =
    | GetMarketWatchlist
    | GetMarketSearchList
    | GetMarketWatchlistSuccess
    | GetMarketWatchlistError
    | GetMarketSearchListSuccess
    | GetMarketSearchlistError
    | PutStarUnstar
    | PutStarUnstarSuccess
    | PutStarUnstarError
    ;
  