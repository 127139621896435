import { call, put } from "redux-saga/effects";
import { fetchApi } from "../saga-index";
import { accountService, errMessage } from "../../../general-config";
import { getClientId, getCookies } from "../../../ui/utils/helper";
import { accountListSagaFormatter } from "./AccountListSagaHelpers";
import { getAccountListFailure, getAccountListSuccess } from "./AccountList.actions";


export const accountListCall = (clientId : string) =>
  fetchApi(`${accountService}/clients/${clientId}/accounts`)

export function* getAccountListCallSaga(): any {
  try {
    const token = getCookies('access_token');
    if(token) {
      let clientId : string = getClientId(token);
      if(clientId) {
        const response = yield call(accountListCall, clientId);//clientid: 8763221
        if(response?.data) {
          const accountList = accountListSagaFormatter(response?.data)
          yield put(getAccountListSuccess(accountList));
        }
        else {
          yield put(getAccountListFailure(errMessage));
        }
      }
    }
    
  } catch (e : any) {
    yield put(getAccountListFailure(e?.response?.data?.message || errMessage));
  }
}