import { call, put } from "redux-saga/effects";
import { errMessage } from "../../../general-config";
import { getCookies, getClientId } from "../../../ui/utils/helper";
import { fetchApi } from "../saga-index";
import { getOutcomeGraphSuccess, getOutcomeGraphFailure } from "./OutcomeGraph.actions";


export const outcomeGraphCall = (clientId : string) =>
  fetchApi(`account-service/v1/clients/${clientId}/allocations/chart`)

export function* getOutcomeGraphSaga(): any {
  try {
    const token = getCookies('access_token');
    if(token) {
      let clientId : string = getClientId(token);
      if(clientId) {
        const response = yield call(outcomeGraphCall, clientId);
        if(response?.data) {
          yield put(getOutcomeGraphSuccess(response?.data));
        }
        else {
          yield put(getOutcomeGraphFailure(errMessage));
        }
      }
    }
    
  } catch (e : any) {
    yield put(getOutcomeGraphFailure(e?.response?.data?.message || errMessage));
  }
}