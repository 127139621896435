import { call, put } from "redux-saga/effects";
import { errMessage } from "../../../general-config";
import { getCookies, getClientId } from "../../../ui/utils/helper";
import { fetchApi } from "../saga-index";
import { getMarketWatchlistSuccess, getMarketWatchlistFailure } from "./MarketWatch.actions";

export const marketWatchlistCall = (clientId : string) =>
  fetchApi(`market-service/v1/markets/favorites?clientId=${clientId}`)

export function* getMarketWatchlistSaga(): any {
  try {
    const token = getCookies('access_token');
    if(token) {
      let clientId : string = getClientId(token);
      if(clientId) {
        const response = yield call(marketWatchlistCall,clientId);
        if(response?.data) {
          yield put(getMarketWatchlistSuccess(response?.data));
        }
        else {
          yield put(getMarketWatchlistFailure(errMessage));
        }
      }
    }
    
  } catch (e : any) {
    yield put(getMarketWatchlistFailure(e?.response?.data?.message || errMessage));
  }
}