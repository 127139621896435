import { GetMarketWatchlistAction, MarketWatchActionType } from "./MarketWatch.action-types";

 
const INITIAL_STATE = {
  marketWatchlist: {},
  isLoading: false,
  error: '',
  searchLoading: false,
  searchErr: '',
  marketSearchlist: [],
  isMarkUnmarkSuccess: false
}
 
const MarketWatchReducer = (state = INITIAL_STATE, action : GetMarketWatchlistAction) => {
  switch (action.type) {
    //WATCH_LIST-------------------------------------
    case MarketWatchActionType.GET_MARKETWATCHLIST:
      return { ...state, isLoading: true };
    case MarketWatchActionType.GET_MARKETWATCHLIST_SUCCESS:
      return { ...state, isLoading: false, marketWatchlist: action.payload };
    case MarketWatchActionType.GET_MARKETWATCHLIST_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    //SEARCH_LIST-------------------------------------
    case MarketWatchActionType.GET_MARKETSEARCHLIST:
      return { ...state, searchLoading: true };
    case MarketWatchActionType.GET_MARKETSEARCHLIST_SUCCESS:
      return {...state, searchLoading: false, marketSearchlist: action.payload};
    case MarketWatchActionType.GET_MARKETSEARCHLIST_ERROR:
      return { ...state, searchLoading: false, searchErr: action.payload };
    
    //STAR/UNSTAR-------------------------------------
    case MarketWatchActionType.PUT_STAR_UNSTAR_SUCCESS:
      return {...state, isMarkUnmarkSuccess: action.payload}

    default:
      return { ...state };
  }
}
 
export default MarketWatchReducer;