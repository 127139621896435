import { call, put } from "redux-saga/effects";
import { fetchApi } from "../saga-index";
import { accountDetailHoldingsFormatter } from "./AccountDetailHoldingHelpers";
import { accountService, errMessage } from "../../../general-config";
import { AccountDetailHoldingActionType } from "./AccountDetailHolding.action-types";
import { getAccountDetailHoldingError, getAccountDetailHoldingSuccess } from "./AccountDetailHolding.actions";

type AccoutSagaType = {
  type: AccountDetailHoldingActionType.GET_ACCOUNTDETAIL_HOLDING | string;
  payload: string;
};

export const holdingcallUrl = (accountId: string) =>
  fetchApi(`${accountService}/accounts/${accountId}`);

export function* getAccountDetailHoldingSaga(accountId: AccoutSagaType): any {
  try {
    const response = yield call(holdingcallUrl, accountId.payload); //clientid: 8763221
    if (response?.data) {
      const accountList = yield call(
        accountDetailHoldingsFormatter,
        response?.data?.holdings
      );

      yield put(
        getAccountDetailHoldingSuccess({
          ...accountList,
          accountName: response?.data.accountTitle,
          accountNumber: response?.data.accountNumber
            ? `****${response?.data.accountNumber.slice(-4)}`
            : "****",
          allocations: response?.data?.allocations,
        })
      );
    } else {
      yield put(getAccountDetailHoldingError(errMessage));
    }
  } catch (e: any) {
    yield put(
      getAccountDetailHoldingError(e?.response?.data?.message || errMessage)
    );
  }
}
