import { assetListHeaders } from "../../../ui/components/mock/AssetHoldings";
import {
  AssetAllocationDataProps,
  AssetAllocationHoldingApiProps,
  AssetAllocationSubHoldingProps,
} from "../../../ui/utils/ApiInterfaces";
import { handleAssetName } from "../AccountDetailHolding/AccountDetailHoldingHelpers";

const getTableData = (item: AssetAllocationDataProps) => {
  return {
    symbol: item?.symbol || "",
    positionName: handleAssetName(item.shortName),
    marketValue: item?.marketValue ? Number(item.marketValue.toFixed(2)) : 0.0,
    portfolioPercent: item?.percentage
      ? Number(item.percentage.toFixed(2))
      : 0.0,
    fullName: item?.name || "",
  };
};
const getListContent = (data: AssetAllocationDataProps[]) => {
  let tempListContent: any = [];
  let tempValue = 0
  data.forEach((item: AssetAllocationDataProps) => {
    if (tempListContent.some((i: any) => i.accountNumber === item.accountNumber)) {
      let index = tempListContent.findIndex(
        (i: any) => i.accountNumber === item.accountNumber
      );
      tempListContent[index].tableData.push(getTableData(item));
      tempValue = tempValue + item.marketValue
    } else {
      let content = { accountName: "", accountNumber: "", tableData: <any>[] };
      content.accountName = item.accountTitle;
      content.accountNumber = item.accountNumber;
      content.tableData.push(getTableData(item));
      tempListContent.push(content);
      tempValue = tempValue + item.marketValue
    }
  });
  let list = {
    listContent: tempListContent,
    totalMarketValue: tempValue ? Number(tempValue.toFixed(2)) : 0.0
  }
  return list;
};
const getAccordionData = (data: AssetAllocationSubHoldingProps[]) => {
  return data.map((item: AssetAllocationSubHoldingProps) => {
    let content = getListContent(item.assets)
    return {
      holdingsSubtitle: item.name,
      accountListHeaders: assetListHeaders,
      accountListContent:content.listContent,
      totalMarketValue: content.totalMarketValue
    };
  });
};
export const assetDetailHoldingsFormatter = (
  data: AssetAllocationHoldingApiProps[]
) => {
  let holdingsData = data.map((item: AssetAllocationHoldingApiProps) => {
    return {
      holdingsTitle: item.className,
      accordionArray: getAccordionData(item.segments),
    };
  });
  const newData = {
    cardTitle: "Holdings",
    holdingsArray: holdingsData,
  };
  return newData;
};
