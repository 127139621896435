import jwtDecode from "jwt-decode";
import { Cookies } from "react-cookie";
import { InternalErrorMsg, NoUserFoundMsg, UnauthErrMsg, badRequestMsg, bdAdminVaultUrl, vaultUrl } from "../../general-config";
import configureStore from 'redux-mock-store';
import { LogoEntity } from "../components/organisms/TopNavigationBar";

const image = require("../images/Loader.webp");

const mediaQuery = require("css-mediaquery");
const cookies = new Cookies();
const createMatchMedia = (width: number) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (query: any) => ({
    matches: mediaQuery.match(query, { width }),
    addListener: () => {},
    removeListener: () => {},
  });
};

export const  testMockStore=()=>{
  const mockStore = configureStore();
  let store:any;
    store = mockStore({});
    return store;
}

export const setScreenSize = (width: number) => {
  Object.defineProperty(window, "innerWidth", {
    writable: true,
    configurable: true,
    value: width,
  });
  window.matchMedia = jest
    .fn()
    .mockImplementation(createMatchMedia(window.innerWidth));
};

export const checkNestedTernaryValidity = (
  conditon: any,
  truthyValue: any,
  falsyValue: any
) => {
  return conditon ? truthyValue : falsyValue;
};

export const checkAndOperatorValidity = (condition: any, truthyValue: any) => {
  return condition && truthyValue;
};

export const checkOrOperatorValidity = (condition: any, truthyValue: any) => {
  return condition || truthyValue;
};

export const trimText = (text: string, threshold: number) => {
  if (text.length <= threshold) return text;
  return text.substring(0, threshold).concat("...");
};

export const getCookies = (value: string) => {
  return cookies.get(value) || "";
};

export const setCookies = (key: string, value: string) => {
  cookies.set(key, value, { path: "/" });
};

export const checkEmptyObject = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

export const getClientId = (token: string) => {
  let decoded: any = jwtDecode(token);
  let tempId = localStorage.getItem("clientId") ?? "";
  let clientId = decoded?.role === "FirmUser" && tempId ? tempId : decoded?.sub;
  return clientId || "";
};

export const getClientEmail = (token: string) => {
  let decoded: any = jwtDecode(token);
  let tempId = localStorage.getItem("clientEmail") ?? "";
  let clientEmail = decoded?.role === "FirmUser" && tempId ? tempId : decoded?.username;
  return clientEmail || "";
};

export const redirectToVault = () => {
  let url = ifFirmUser() ? bdAdminVaultUrl : vaultUrl;
  window?.open(url, "_blank");
};

export const ifFirmUser = () => {
  let user = localStorage.getItem("role") ?? "";
  return user === "FirmUser";
};

export const getCurrentDate = () => {
  const date = new Date();
  let dateStr = date.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
  let time = date?.toLocaleTimeString("en-US");
  return dateStr && time ? `${dateStr} ${time}` : "";
};

export const getPdfDate = () => {
  let date = getCurrentDate();
  date = date.replace(/ /g, "_").replace(",", "")
  return date;
};

export const getToFixed = (num: number) => {
  let arr = num?.toString()?.split(".");
  let str = arr && parseInt(arr[0])?.toLocaleString("en-US", { currency:"USD"});
  let temp = typeof num === "number" ? num?.toFixed(2)?.split(".")[1] : "00";
  return str && temp ? `${str}.${temp}` : num;
};

export const getBackground = ( number: number) => {
  if (number === 0) {
    return "rgba(83, 96, 108, 0.19)";
  } else if (number < 0) {
    return "rgba(190, 31, 40, 0.12)";
  } else if (number > 0) {
    return "rgba(0, 150, 94, 0.12)";
  } else return "rgba(83, 96, 108, 0.19)";
};

export const isNoHeader = () => {
  const location = window.location.pathname || "";
  if( location === "/sessionExpired" || location === "/404" || location === "/logout")
    return true;
  return false;
}

export const handleLogoutFn = () => {
  logoutAction();
  window.location.href = "/logout";
};

export const logoutAction = () => {
  setCookies("access_token", "");
  localStorage.removeItem("expiration");
  localStorage.removeItem("role");
  localStorage.removeItem("clientName");
  localStorage.removeItem("clientId");
  localStorage.removeItem('portfolioId');
  localStorage.removeItem('themeMode');
  localStorage.removeItem('layout')
  localStorage.removeItem('THEME_MODE');
  localStorage.removeItem('clientEmail');
};
export const backHandlerEvents = () => {
  const referrer = document.referrer;
  const currPage = window.location.pathname;
  const origin = window?.location?.origin;
  console.log("referrer--->", referrer, currPage , origin);
  if(checkAndOperatorValidity(checkOrOperatorValidity(referrer.includes("login.bdreporting.com") , referrer === `${origin}/`) , currPage === "/" )) {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', (event : any ) => {
      window.history.pushState(null, document.title, window.location.href);
    }); 
  }
}

export const navToSessionExpired = (error : any) => {
  let errorCode = error?.response?.status ;
  let navUrl = "/sessionExpired"
  logoutAction();
  switch (errorCode) {
    case 404:
      navUrl += `?error=${NoUserFoundMsg}`;
      break;
    case 500:
      navUrl += `?error=${InternalErrorMsg}`;
      break;
    case 401:
      navUrl += `?error=${UnauthErrMsg}`;
      break;
    case 400:
      navUrl += `?error=${badRequestMsg}`;
      break;
    default:
      return navUrl;
  }
  window.location.href = navUrl;
}

export const getDashboardLayout = (width:number) => {
  return [
    { i: "a", mX: 0, dX:0, mY: 0,  dY: 0, mW: 12, dW: 4, mH: 3.72, dH: 3.74 },
    { i: "b", mX: 0, dX:4, mY: 0,  dY: 0, mW: 12, dW: 4, mH: 2.45, dH: 3.74 },
    { i: "c", mX: 0, dX:8, mY: 0,  dY: 0, mW: 12, dW: 4, mH: width <=400 ? 3.19 : 2.71, dH: 3.74 },
    { i: "d", mX: 0, dX:0, mY: 0,  dY: 1, mW: 12, dW: 12, mH: 3.62, dH: 3.87 },
    { i: "e", mX: 0, dX:0, mY: 0,  dY: 2, mW: 12, dW: 12, mH: 1.81, dH: 1.61 },
    { i: "f", mX: 0, dX:0, mY: 0,  dY: 3, mW: 12, dW: 6, mH: 3.95, dH: 3.93 },
    { i: "g", mX: 0, dX:6, mY: 0,  dY: 3, mW: 12, dW: 6, mH: 3.95, dH: 3.93 },  
  ]
}

export const emptyObject = (data:any) => {
  let isEmpty = true;
  if(data && data !== 'undefined' && data !== null) {
      isEmpty = Object.keys(data).length === 0 && data.constructor === Object;
  }
  return isEmpty;
}

export const widgetH:any = {
  a: {desktop: 3.71, mobile: 3.71},
  b: {desktop: 3.71, mobile: 2.45},
  c: {desktop: 3.71, mobile: 2.71, smallMobile: 3.18},
  d: {desktop: 3.87, mobile: 3.62},
  e: {desktop: 1.61, mobile: 1.61},
  f: {desktop: 3.93, mobile: 3.93},
  g: {desktop: 3.93, mobile: 3.93}
}

export const widgetW:any = {
  a: {desktop: 4, mobile: 12},
  b: {desktop: 4, mobile: 12},
  c: {desktop: 4, mobile: 12},
  d: {desktop: 12, mobile: 12},
  e: {desktop: 12, mobile: 12},
  f: {desktop: 6, mobile: 12},
  g: {desktop: 6, mobile: 12}  
}

export const getInitialLayout = () => {
  return {
    desktopLayout: {
      lg: [
        { i: "a", x: 0, y: 0, w: widgetW['a']['desktop'], h: widgetH['a']['desktop'] },
        { i: "b", x: 4, y: 0, w: widgetW['b']['desktop'], h: widgetH['b']['desktop'] },
        { i: "c", x: 8, y: 0, w: widgetW['c']['desktop'], h: widgetH['c']['desktop'] },
        { i: "d", x: 0, y: 1, w: widgetW['d']['desktop'], h: widgetH['d']['desktop'] },
        { i: "e", x: 0, y: 2, w: widgetW['e']['desktop'], h: widgetH['e']['desktop'] },
        { i: "f", x: 0, y: 3, w: widgetW['f']['desktop'], h: widgetH['f']['desktop'] },
        { i: "g", x: 6, y: 3, w: widgetW['g']['desktop'], h: widgetH['g']['desktop'] },
      ],
    },
    mobileLayout: {
      lg: [
        { i: "a", x: 0, y: 0, w: widgetW['a']['mobile'], h: widgetH['a']['mobile'] },
        { i: "b", x: 0, y: 0, w: widgetW['b']['mobile'], h: widgetH['b']['mobile'] },
        { i: "c", x: 0, y: 0, w: widgetW['c']['mobile'], h: widgetH['c']['mobile'] },
        { i: "d", x: 0, y: 0, w: widgetW['d']['mobile'], h: widgetH['d']['mobile'] },
        { i: "e", x: 0, y: 0, w: widgetW['e']['mobile'], h: widgetH['e']['mobile'] },
        { i: "f", x: 0, y: 0, w: widgetW['f']['mobile'], h: widgetH['f']['mobile'] },
        { i: "g", x: 0, y: 0, w: widgetW['g']['mobile'], h: widgetH['g']['mobile'] },
      ]
    }
  };
};

export const getPdfTime=()=>{
  const date = new Date();
  let dateStr = date.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
  let time = date?.toLocaleTimeString("en-US",{hour: '2-digit', minute: '2-digit'});
  return  dateStr && time ? `${dateStr} ${time}` : "";
}

export const getLogo = (isFirmUserLanding:boolean, stickyClass:string, logo: LogoEntity, themeData:any) => {
  if (isFirmUserLanding && stickyClass != "fixed") {
    return logo?.whiteLogoUrl;
  } else if (isFirmUserLanding && stickyClass === "fixed") {
    return logo?.colorLogoUrl;
  } else if (
    !isFirmUserLanding &&
    stickyClass === "fixed" &&
    themeData?.find((f:any)=> f?.type === 'themeMode') &&
    !emptyObject(themeData?.find((f:any)=> f?.type === 'themeMode' && f?.value === 'light'))
  ) {
    return logo?.colorLogoUrl;
  } else {
    return logo?.whiteLogoUrl;
  }
} 

export const getHeightOfOutcomeSmallMobile = (graph:any) => {
  let ht = widgetH['c'].smallMobile
  if(graph?.length === 11){
    ht = 3.85
  } else if([9,10].includes(graph?.length)){
    ht = 3.65
  } else if([6,7,8].includes(graph?.length)){
    ht = 3.52
  }
  return ht   
}

export const sampleError = () => {
  return {
    "code": 500,
    "method": "GET",
    "message": "Internal Server Erro"
  }
}

export const getBackgroundImage = (isFirmUserLanding: boolean, theme: string) => {
  if (isFirmUserLanding) {
    return `url(${image})`;
  } else if (!isFirmUserLanding && theme === "light") {
    return `url(${image})`;
  } else {
    return `linear-gradient(0deg, rgba(25, 25, 26, 0.5), rgba(25, 25, 26, 0.5)),url(${image})`;
  }
};