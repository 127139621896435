import { call, put } from "redux-saga/effects";
import { fetchApi, mgpPortfolio } from "../saga-index";
import { errMessage, mgpService } from "../../../general-config";
import { getCookies } from "../../../ui/utils/helper";
import { networthGraphFormatter } from "./NetWorthGraphFormatterHelpers";
import { getNetWorthSuccess, getNetWorthError } from "./NetWorth.actions";


export const networthGraphCall = (portfolioId : string) =>
  fetchApi(`${mgpService}/networth?portfolioId=${portfolioId}`)

export function* getNetworthGraphSaga(): any {
  try {
    const token = getCookies('access_token');
    if(token) {
      const portfolioId = yield call(mgpPortfolio);
      if(portfolioId) {
        const response = yield call(networthGraphCall, portfolioId);
        if(response?.data) {
          const graphData = yield call(
            networthGraphFormatter,
            response?.data
          );
          yield put(getNetWorthSuccess(graphData));
        }
        else {
          yield put(getNetWorthError({errorMessage:errMessage,responseCode:500}));
        }
      }
      else {
        yield put(getNetWorthError({errorMessage:errMessage,responseCode:500}));
      }
    }
    
  } catch (e : any) {
    yield put(getNetWorthError({errorMessage:e?.response?.data?.message || errMessage,responseCode:e?.response?.data?.code||500}));
  }
}