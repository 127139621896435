import { ColorPalette } from "./colorTheme.type";

const lightPallete: ColorPalette = {
  palette: {
    mode: "light",
    primary: {
      main: "#00965E",
      light: "rgba(0, 150, 94, 0.12)",
      contrastText: "#FFF",
    },
    text: {
      primary: "#181A19",
      disabled: "#7B827F",
      secondary: "#221C35",
    },
    error: {
      main: "#BE1F28",
      light: "rgba(190, 31, 40, 0.12)",
    },
    common: {
      mainBackground:
        "linear-gradient(0deg, rgba(83, 96, 108, 0.05), rgba(83, 96, 108, 0.05)), rgba(246, 247, 248, 0.85)",
      lightBackground: "#FFF",
      hint: "#53606C",
      viewCta: "#00965E",
      marketSymbol: "#53606C",
      marketAmount: "#221C35",
      searchBar:
        "linear-gradient(0deg, rgba(83, 96, 108, 0.05), rgba(83, 96, 108, 0.05)), rgba(246, 247, 248, 0.85)",
      searchBarOpen: "#FFFFFF",
      marketWatchHeaders: "#221C35",
      zeroBackground: "rgba(83, 96, 108, 0.19)",
      zeroText: "#181A19",
      greenText: "#00965E",
      accountListBackground:
        "linear-gradient(0deg, rgba(83, 96, 108, 0.05), rgba(83, 96, 108, 0.05)), rgba(246, 247, 248, 0.85)",
      marketSymbolMobile: "#53606C",
      drawerBackground: "rgba(255, 255, 255, 1)",
      switchThumbBackground:'#221C35',
      switchTrackBackground:'#FFFFFF',
      switchTrackBorder:'1px solid #181A19',
      marketWatchSearchIcon:'#181A19',
      searchIcon:'#181A19',
      searchDrawerText:'rgb(24, 26, 25)',
      divider:'#7B827F',
      stickyNavBar:'#EEEFF1',
      contactModal:'#FFFFFF',
      graphBackground:'linear-gradient(0deg, rgba(83, 96, 108, 0.05), rgba(83, 96, 108, 0.05)), rgba(246, 247, 248, 0.85)',
      graphDrawer:'#FFFFFF',
      graphDrawerContent:'#FFFFFF !important',
      accordionBgColor: 'white !important',
      accordionBorderBottom: '0.5px solid #F6F7F8 !important',
      contactDrawer: "#FFFFFF",
      searchTooltipBG: 'rgba(83, 96, 108, 0.19)',
      searchTooltipFont: '#000000',
      errorCard: "#181A19",
      errorBg: "rgb(180 180 189 / 60%)"
    },
  },
  typography: {
    label: {},
    title: {
      fontSize: 22,
    },
  },
};

export default lightPallete;
