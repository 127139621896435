import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";

type SeesionExpiredType={
  open:boolean
}
export const SessionExpiredPopup = (props: SeesionExpiredType) => {
  const { open } = props;
  
  const handleClick = () => {
    window.location.href = "/sessionExpired";
  };

  return (
    <Dialog
      open={open}
        onClose={handleClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "#ffffff", 
          color: "#221c35"
        },
      }}
    >
     
      <DialogContent >
        <DialogContentText id="alert-dialog-description" style = {{backgroundColor: "#FFF", color: "#221c35"}}>
          Your session has expired due to inactivity. Please log in again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  onClick={handleClick} style = {{backgroundColor: "#FFF", color: "#00965E"}}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionExpiredPopup;
