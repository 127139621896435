export enum AccountListActionType {
  GET_ACCOUNTLIST_ERROR = 'GET_ACCOUNTLIST_ERROR',
  GET_ACCOUNTLIST = 'GET_ACCOUNTLIST',
  GET_ACCOUNTLIST_SUCCESS = 'GET_ACCOUNTLIST_SUCCESS'
}
interface GetAccountList {
  type: AccountListActionType.GET_ACCOUNTLIST;
  payload?: any;
}
interface GetAccountListSuccess {
  type: AccountListActionType.GET_ACCOUNTLIST_SUCCESS;
  payload: any;
}

interface GetAccountListError {
  type: AccountListActionType.GET_ACCOUNTLIST_ERROR;
  payload: any;
}
  
export type GetAccountListAction =
  | GetAccountList
  | GetAccountListSuccess
  | GetAccountListError;