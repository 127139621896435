import { call, put } from "redux-saga/effects";
import { accountService, getUserPrefError, errMessage, setUserPrefError } from "../../../general-config";
import { getCookies, getClientId, getInitialLayout } from "../../../ui/utils/helper";
import { fetchApi, fetchPutApi } from "../saga-index";
import { getUserPreferenceSuccess, getUserPreferenceFailure, putUserPreferenceSuccess, putUserPreferenceFailure } from "./UserPreferences.actions";

const handleResponse = (data:any) => {
  const defaultMode = data?.find(
    (el: any) => el.type === "themeMode"
  )?.value || 'light'
  const layout = data?.find(
    (el: any) => el.type === "layout"
  )?.value;
  localStorage.setItem("themeMode", defaultMode);
  if(layout){
    localStorage.setItem('layout', JSON.stringify(layout))
  }
}

export const userPreferenceCall = (clientId: string) =>
  fetchApi(`${accountService}/clients/${clientId}/preferences`);

export function* getUserPreferenceCallSaga(): any {
  try {
    const token = getCookies("access_token");
    if (token) {
      let clientId: string = getClientId(token);
      if (clientId) {
        const response = yield call(userPreferenceCall, clientId); //clientid: 8763221
        if (response?.data) {
          if (response.data.length === 0){
            let defaultPref = [
              {
                type: "themeMode",
                value: "light",
              },
              { type: "layout",
               value: getInitialLayout(),
              }
            ]
            localStorage.setItem("themeMode", "light");
            localStorage.setItem("layout", JSON.stringify(getInitialLayout()))
            yield put(getUserPreferenceSuccess(defaultPref));
          } else {
            handleResponse(response?.data)
            yield put(getUserPreferenceSuccess(response.data));
          }
        } else {
          yield put(getUserPreferenceFailure(getUserPrefError));
        }
      }
    }
  } catch (e: any) {
    yield put(
      getUserPreferenceFailure(e?.response?.data?.message || errMessage)
    );
  }
}

export const userPreferencePutCall = (clientId: string, value: string) =>
  fetchPutApi(`${accountService}/clients/${clientId}/preference`, value);

export function* putUserPreferenceCallSaga(payload: any): any {
  try {
    const token = getCookies("access_token");
    if (token) {
      let clientId: string = getClientId(token);
      if (clientId) {
        const response = yield call(
          userPreferencePutCall,
          clientId,
          payload.payload
        );
        if (response) {
          if(payload?.payload?.type === 'themeMode'){
            localStorage.setItem("themeMode", payload?.payload?.value);
          } else if(payload?.payload?.type === 'layout'){
            localStorage.setItem("layout", JSON.stringify(payload?.payload?.value)); 
          }
          yield put(putUserPreferenceSuccess(payload.payload));
        } else {
          yield put(putUserPreferenceFailure(setUserPrefError));
        }
      }
    }
  } catch (e: any) {
    yield put(
      putUserPreferenceFailure(e?.response?.data?.message || errMessage)
    );
  }
}