import { accountHoldingHeaders } from "../../../ui/components/mock/AccountHoldings";
import { AccountDetailApiResponse, AssetsApi, SegmentsApi } from "../../../ui/utils/ApiInterfaces/AccountDetailHoldingApi";

const handleDayChange = (data: number) => {
  if (data && data !== undefined) {
    return data;
  } else {
    return 0;
  }
};

export const handleAssetName = (shortName: string | undefined) => {
  if (shortName !== undefined) {
      return shortName;
  }
   else return "";
};

const getListContent = (data:AssetsApi []) => {
  let tempData = {
    assets : {},
    totalValue : 0
  }
  let tempValue = 0;
  tempData.assets = data.map((item: AssetsApi) => {
    tempValue = tempValue + item?.marketValue
    return {
      symbol: item?.symbol || "",
      assetName: handleAssetName(item.shortName),
      percentHolding: item?.percentage
        ? Number((item.percentage).toFixed(2))
        : 0.00,
      marketValue: item?.marketValue
        ? Number((item.marketValue).toFixed(2))
        : 0.00,
      dayChange: handleDayChange(item?.dayChange),
      percentDayChange: handleDayChange(item?.dayChangePercent),
      shares: item?.units ? Number((item.units).toFixed(2)) : 0.00,
      fullName:item?.name || '',
    };
  });
  tempData.totalValue = tempValue ? Number((tempValue).toFixed(2)) : 0.00
  return tempData ; 
};

const getAccordionData = (data:SegmentsApi []) => {
  return data.map((item: SegmentsApi) => {
    let listContent = getListContent(item.assets)
    return {
      holdingsSubtitle: item.name,
      accountListHeaders: accountHoldingHeaders,
      accountListContent: listContent.assets,
      totalMarketValue: listContent.totalValue
    };
  });
};

export const accountDetailHoldingsFormatter = (data: AccountDetailApiResponse[]) => {
  let holdingsData = data.map((item: AccountDetailApiResponse) => {
    return {
      holdingsTitle: item.className,
      accordionArray: getAccordionData(item.segments),
    };
  });
  const newData = {
    cardTitle: "Holdings",
    holdingsArray: holdingsData,
  };
  return newData;
};
