import { call, put } from "redux-saga/effects";
import { fetchApi } from "../saga-index";
import { getClientId, getCookies } from "../../../ui/utils/helper";
import { errMessage } from "../../../general-config";
import { getMarketSearchListSuccess, getMarketSearchlistFailure } from "./MarketWatch.actions";


export const marketSearchlistCall = (clientId : string, payload: string) =>
  fetchApi(`market-service/v2/markets/watch-list?query=${payload}&clientId=${clientId}`)
  

export function* getMarketSearchlistSaga(payload:{type: string, payload: string}): any {
  try {
    const token = getCookies('access_token');
    if(token) {
      let clientId : string = getClientId(token);
      if(clientId) {
        const response = yield call(marketSearchlistCall, clientId, payload.payload);
        if(response?.data) {
          yield put(getMarketSearchListSuccess(response?.data));
        }
        else {
          yield put(getMarketSearchlistFailure(errMessage));
        }
      }
    }
    
  } catch (e : any) {
    yield put(getMarketSearchlistFailure(e?.response?.data?.message || errMessage));
  }
}