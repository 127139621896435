import { AdminActionType, GetAdminDetailsActionType } from "./Admin.action-types";

 
const INITIAL_STATE = {
  clientList: {},
  isLoading: false,
  error: '',
}
 
const AdminReducer = (state = INITIAL_STATE, action : GetAdminDetailsActionType) => {
    switch (action.type) {
        case AdminActionType.GET_CLIENTS:
          return { ...state, isLoading: true };
        case AdminActionType.GET_CLIENTS_SUCCESS:
          return { ...state, isLoading: false, clientList: action.payload };
        case AdminActionType.GET_CLIENTS_ERROR:
          return { ...state, isLoading: false, error: action.payload };
        default:
          return { ...state };
    }
}
 
export default AdminReducer;