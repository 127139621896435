import { call, put } from "redux-saga/effects";
import { errMessage } from "../../../general-config";
import { getCookies, getClientId } from "../../../ui/utils/helper";
import { fetchPutApi } from "../saga-index";
import { getMarketWatchlist, putStartUnstarSuccess, putStartUnstarFailure } from "./MarketWatch.actions";

export const marketCall = (payload: {symbol:string, favorite: boolean}) =>
  fetchPutApi(`market-service/v1/markets/favorite`, payload)
  
export function* getMarketStarUnstarSaga(payload:{type: string, payload: {symbol: string, favorite: boolean}}): any {
  try {
    const token = getCookies('access_token');
    if(token) {
      let clientId : string = getClientId(token);
      if(clientId) {
        const response = yield call(marketCall, payload.payload);
        if(response?.data?.status === "OK") {
            yield put(putStartUnstarSuccess(true));
            yield put(getMarketWatchlist());
        }
        else {
          yield put(putStartUnstarFailure(errMessage));
        }
      }
    }
    
  } catch (e : any) {
    yield put(putStartUnstarFailure(e?.response?.data?.message || errMessage));
  }
}