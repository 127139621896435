import jwtDecode from "jwt-decode";
import { AccountListResponseType } from "./AccountListSagaHelper.type";
import { setCookies } from "../../../ui/utils/helper";

export const accountListSagaFormatter = ( data : AccountListResponseType[]) => {
  let temp = data?.map((item : any) => {
    return {
      accountTitle: item?.accountTitle || "",
      accountNumber: item?.accountNumber ? `****${item.accountNumber.slice(-4)}` : "****",
      accountCustodian: item?.custodian || "",
      accountType: item?.accountType || "",
      totalAccountValue: item?.totalValue ? Number((item.totalValue).toFixed(2)) : 0.00 , 
      action: "View",
      accountId: item?.accountId || ""
    }
  });

  let totalInvestment = data.reduce((r : number, d : any) => {
    return r +  Number((d.totalValue).toFixed(2)) } , 0 );
  let listData = {
    accountListLabel: "My Accounts",
    ctaLabel:'View Black Diamond',
    accountListHeaders: [
      { id: "accountTitle", label: "Account Title" },
      { id: "accountNumber", label: "Account Number" },
      { id: "accountCustodian", label: "Account Custodian" },
      { id: "accountType", label: "Account Type" },
      { id: "totalAccountValue", label: "Total Account Value" },
      { id: "action", label: "Action" },
    ],
    totalInvestment: { label: "Total Investments", value: totalInvestment ? Number((totalInvestment).toFixed(2)) : 0.00 },
    accountListContent: temp
  }
  return listData;
}

export const setResponse = (token : string) => {
  setCookies('access_token', token);
  let decoded : any = jwtDecode(token);
  localStorage.setItem("expiration", decoded?.exp)
  localStorage.setItem("role", decoded?.role)
  if(decoded?.role === "FirmUser") {
    return true;
  }
  return false;
}
