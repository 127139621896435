import { AccountListActionType, GetAccountListAction } from "./AccountList.action-types";

 
const INITIAL_STATE = {
  accountList: {},
  isLoading: false,
  error: '',
}
 
const AccountListReducer = (state = INITIAL_STATE, action : GetAccountListAction) => {
  switch (action.type) {
    case AccountListActionType.GET_ACCOUNTLIST:
      return { ...state, isLoading: true };
    case AccountListActionType.GET_ACCOUNTLIST_SUCCESS:
      return { ...state, isLoading: false, accountList: action.payload };
    case AccountListActionType.GET_ACCOUNTLIST_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return { ...state };
  }
}
 
export default AccountListReducer;