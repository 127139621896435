import { call, put } from "redux-saga/effects";
import { getRequest } from "../../../api/api-config";
import { getCookies } from "../../../ui/utils/helper";
import { setResponse } from "../AccountList/AccountListSagaHelpers";
import { refreshAuthSuccess, refreshAuthFailure } from "./Auth.actions";


export function* refreshAuthCall(token : string):any {
  const response = yield getRequest(`auth-service/refresh-token?refreshToken=${token}`);
  setResponse(response?.data?.toString())
  yield put(refreshAuthSuccess(response?.data));
  return response.status;
}

export function* getRefreshToken(): any {
  const token = getCookies('access_token') || null;
  try {
    if(token) {
      return yield call(refreshAuthCall , token)
    }
  } catch (e) {
    yield put(refreshAuthFailure(e));
    window.location.href = "/sessionExpired";
  }
}