import { GetMoneyGuideAction, MoneyGuideActionType } from "./MoneyGuide.action-types";

const INITIAL_STATE = {
  moneyGuideImage: {},
  isLoading: false,
  error: '',
  responseCode:200
}
 
const MoneyGuideReducer = (state = INITIAL_STATE, action : GetMoneyGuideAction) => {
  switch (action.type) {
    case MoneyGuideActionType.GET_MONEYGUIDE_IMAGE:
      return { ...state, isLoading: true };
    case MoneyGuideActionType.GET_MONEYGUIDE_IMAGE_SUCCESS:
      return { ...state, isLoading: false, moneyGuideImage: action.payload , error: "",responseCode:200};
    case MoneyGuideActionType.GET_MONEYGUIDE_IMAGE_ERROR:     
      return { ...state, isLoading: false, error: action.payload.errorMessage,responseCode:action.payload.responseCode};
    default:
      return { ...state };
  }
}
 
export default MoneyGuideReducer;