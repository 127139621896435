import { MoneyGuideActionType } from "./MoneyGuide.action-types";

export const getMoneyGuideImage = () => ({
    type: MoneyGuideActionType.GET_MONEYGUIDE_IMAGE
});

export const getMoneyGuideImageSuccess = (payload : any) => ({
    type: MoneyGuideActionType.GET_MONEYGUIDE_IMAGE_SUCCESS,
    payload
});

export const getMoneyGuideImageFailure = (error : any) => ({
    type: MoneyGuideActionType.GET_MONEYGUIDE_IMAGE_ERROR,
    payload: error
})