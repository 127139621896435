import { NetWorthAction, NetWorthActionType } from "./NetWorth.action-types";
 
const INITIAL_STATE = {
  networthGraph: {},
  isLoading: false,
  error: '',
  responseCode:200
}
 
const NetWorthReducer = (state = INITIAL_STATE, action : NetWorthAction) => {
  switch (action.type) {
    case NetWorthActionType.GET_NETWORTH:
      return { ...state, isLoading: true };
    case NetWorthActionType.GET_NETWORTH_SUCCESS:
      return { ...state, isLoading: false, networthGraph: action.payload , error: "",responseCode:200};
    case NetWorthActionType.GET_NETWORTH_ERROR:
      return { ...state, isLoading: false, error: action.payload.errorMessage,responseCode:action.payload.responseCode};
    default:
      return { ...state };
  }
}
 
export default NetWorthReducer;